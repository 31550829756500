@use '../partials/base';

.ios-app-link {
  display: block;
  overflow: hidden;
  width: 135px;
  height: 40px;
  margin: 2em auto;
  background-size: contain;
  transform: scale(1.15);

  @media only screen and (min-width: base.$desktop) {
    bottom: 1.5em;
    position: absolute;
    left: 0;
    margin-left: 2em;
  }
}
