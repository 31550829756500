@use '../partials/base';

.modal-title {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding-right: 1em;
  padding-left: 1em;
  background-color: #002840;
  color: white;
  text-transform: uppercase;
}

.close-modal {
  color: rgba(255, 255, 255, 0.6);
  size: 2em;
  cursor: pointer;
  border: none;
  background-color: #002840;
}

.ReactModal__Body--open {
  height: 10em;
}

dl.faq-dl {
  padding: 1em 2em;
}

dl.faq-dl dt,
.question {
  font-weight: bold;
}

dl.faq-dl dd,
.answer {
  padding-bottom: 1em;
  color: #75797e;
  margin-inline-start: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}

.faq-modal-error {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: white;
  outline: none;

  @media screen and (min-width: base.$tablet) {
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 10%;
  }

  @media screen and (min-width: base.$xlarge) {
    top: 15%;
    left: 15%;
    right: 15%;
    bottom: 15%;
  }

  @media screen and (max-height: 500px) {
    $vertical_margin: 1px;
    top: 0;
    bottom: 0;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.content {
  height: 90%;
  overflow: auto;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 1em;
}

.modal-link-button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: #6f6f6d;
}
