.unsubscribe-page {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.unsubscribe-content {
  line-height: 1.4em;
}

.unsubscribe-page .buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .unsubscribe-button,
  .visit-button {
    width: 14em;
    font-size: 13px;
    padding: 11px;
    margin: 1em 0.5em 0.5em 0.5em;
  }
}

h1.h1-unsubscribe-error {
  font-size: 1.5em;
}

.unsubscribe-error {
  display: flex;

  .error-icon {
    margin-right: 0.3em;
  }
}
