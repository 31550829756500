@use '../partials/base';

// base style
.svg-icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  fill: #75797e;
}

// navbar icons
.nav-globe-icon {
  fill: white;
  margin-right: 0.25em;
}

.nav-carat-icon {
  fill: white;
  opacity: 0.5;
}

// loading icons
.loading-icon {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.main-loading-icon {
  width: 45px;
  height: 45px;
}

// global alert icons
.global-alert-icon {
  display: none;
  align-self: center;

  width: 35px;
  height: 35px;
  margin-left: 2em;

  @media only screen and (min-width: base.$desktop) {
    display: inline;
  }
}

.social-icon {
  border-radius: 50%;
  fill-rule: evenodd;
  border-style: solid;
  border-width: 0.1em;

  .social-svg-background {
    fill: transparent;
  }

  .social-svg-mask {
    fill: transparent;
  }

  &.facebook {
    border-color: rgb(59, 89, 152);

    .social-svg-icon {
      fill: rgb(59, 89, 152);
      transform: scale(1.3) translate(-0.5em, -0.5em);
    }
  }

  &.twitter {
    border-color: rgb(0, 172, 237);

    .social-svg-icon {
      fill: rgb(0, 172, 237);
      transform: scale(1.3) translate(-0.5em, -0.5em);
    }
  }

  &.twitter-reverse {
    border-color: rgb(0, 172, 237);

    .social-svg-background,
    .social-svg-icon {
      fill: transparent;
    }

    .social-svg-mask {
      fill: rgb(0, 172, 237);
    }
  }

  &.youtube {
    border-color: rgb(255, 51, 51);

    .social-svg-icon {
      fill: rgb(255, 51, 51);
      transform: scale(1.5) translate(-0.6em, -0.6em);
    }
  }
}

.map-marker-icon {
  fill: #ff0000;

  &.grey {
    fill: #f0f0f0;
  }
}
