@use '../partials/base';

.logo-county {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 1.1em;
  letter-spacing: 0.03em;

  .logo-container {
    position: relative;
    top: 0.15em;
    text-decoration: none;
    color: white;
    padding: 0;
    margin: 0;

    img {
      display: none;
      position: relative;
      top: -4px;

      @media only screen and (min-width: base.$tablet) {
        display: initial;
      }
    }
  }

  .logo-county-spacer {
    display: none;

    @media only screen and (min-width: base.$tablet) {
      display: initial;
    }
  }
}
