@use '../partials/base';

.pagination {
  margin-top: 0.9em;

  .pagination-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    color: #000000;
  }

  .button-container {
    display: flex;
    width: fit-content;
  }

  .disabled {
    color: #75797e;
  }

  .page-count {
    font-weight: bold;
    min-width: fit-content;
  }

  button {
    border: none;
    text-transform: uppercase;
    line-height: 25px;
    background-color: transparent;
    color: #000000;
    cursor: pointer;

    .previous-arrow {
      margin-right: -0.4em;
    }

    .next-arrow {
      margin-left: -0.4em;
      vertical-align: bottom;
    }

    .arrow-left,
    .arrow-right {
      vertical-align: bottom;
    }
  }

  .prev-button,
  .next-button {
    width: max-content;
  }

  button.disabled {
    cursor: not-allowed;
  }
}
