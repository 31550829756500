@use '../partials/base';

.ambiguous-container {
  flex: 1 1 auto;
  @media screen and (min-width: base.$desktop) {
    margin: 3em 0;
  }
}

.ambiguous-title-section {
  margin: 3em 0 2.5em 0;

  .header {
    color: base.$primary-color;
    font-size: 1.2em;
    margin: 0;
  }

  @media screen and (min-width: base.$desktop) {
    margin: 0 0 2em 0;
  }
}

.ambiguous-login-form section {
  background-color: white;

  label {
    display: block;
    font-size: 1em;
    color: base.$primary-color;
    text-transform: uppercase;
  }

  input {
    margin-top: 1em;
    width: 100%;
    border: none;
    border-bottom: 1px solid grey;
  }

  input::placeholder {
    color: #c0c0c0;
  }
}

.ambiguous-buttons-container {
  display: flex;
  flex-flow: column;
  margin: 2em 0;

  > button {
    border-radius: 3px;
    padding: 1em;
  }

  .primary-button {
    margin-bottom: 1em;
  }

  .secondary-button {
    background-color: #f0f0f0;
    color: black;
  }

  @media screen and (min-width: base.$phone) {
    flex-flow: row;

    .primary-button {
      margin-bottom: 0;
      flex: 3 0 auto;
      margin-right: 1em;
    }

    .secondary-button {
      flex: 1 1 auto;
    }
  }

  @media screen and (min-width: base.$largePhone) {
    margin: 2em auto;
    width: 80%;
  }

  @media screen and (min-width: base.$tablet) {
    width: 70%;
  }

  @media screen and (min-width: base.$desktop) {
    flex-flow: column;
    width: 100%;

    .primary-button {
      margin: 0 0 1em 0;
    }
  }

  @media screen and (min-width: base.$xlarge) {
    flex-flow: row;

    .primary-button {
      flex: 3 0 auto;
      margin: 0 1em 0 0;
    }

    .secondary-button {
      flex: 1 1 auto;
    }
  }
}
