.card {
  width: 90%;
  box-shadow: 1.1px 1.5px 2.1px #ccc;
  border-radius: 6px;
  font-size: 0.9em;
  border-left: 0.5px solid rgba(black, 0.1);
  place-self: center;
  min-width: 90%;

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #03314c;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    height: 3em;
    color: white;
    padding: 1.25em;
  }

  .card-header-title {
    text-transform: uppercase;
    font-weight: bold;
  }

  .card-header-subtitle {
    text-transform: uppercase;
    font-weight: normal;
    color: #b4cdea;
  }

  .card-detail {
    padding: 1.5em 1.25em;
    height: 90%;
  }
}
