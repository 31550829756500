@use '../partials/base';

.nav-bar {
  background-color: base.$primary-color;
  display: flex;
  width: 100%;
  height: base.$nav-height;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0 1.25em;

  @media only screen and (min-width: base.$tablet) {
    padding: 0 10%;
  }

  @media only screen and (min-width: base.$desktop) {
    padding: 0 1.5em 0 2em;
  }
}

.nav-bar-dropdown-icon {
  opacity: 0.5;
  width: 10px;
  height: 10px;
}
