@use '../partials/base';

.faq-page {
  height: unset;

  main {
    .faq-error {
      display: flex;
      justify-content: center;
    }

    p span {
      font-weight: bold;
    }

    dl.faq-page-dl {
      padding: 0;
    }

    dl.faq-page-dl dt,
    .question {
      font-weight: bold;
    }

    dl.faq-page-dl dd,
    .answer {
      margin-bottom: 1.5em;
      margin-inline-start: 0;
      margin-top: 1em;
      color: #75797e;
    }
  }
}
