@use '../partials/base';

.loading {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: base.$secondary-color;
}
