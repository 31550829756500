//# create global variables and theme environment here, i.e. theme colors, fonts, weights
// colors
$primary-color: #03314c;
$secondary-color: #f0f0f0;
$alert-color: #b50000;
$success-color: #43ac6a;
$info-color: #696969;
$text-gray: #6f6f6d;
$link-blue: #337ab7;

// breakpoints
$high-res: '1800px';
$xlarge: '1200px';
$desktop: '992px';
$largeTablet: '900px';
$tablet: '768px';
$largePhone: '500px';
$phone: '425px';
$xsmall: '375px';
$xxsmall: '360px';

// Fonts
$primary-font: 'Lato', sans-serif;
$new-theme-font: 'Open Sans', sans-serif;

// Key measurements
$nav-height: 4em;

// colors from account
$ballot-blue: #194788;
$even-table-row: #e8ecf3;
$light-gray: #8f8f8f;
$error-red: #d45659;
$ballot-red: #af1017;
$disabled-color: #b4b4b4;
$voter-blue: #03314c;
