@use '../partials/base';

.past-ballots-list {
  padding: 1em 0;
  background-color: white;

  @media screen and (min-width: base.$tablet) {
    padding: 1em;
  }

  .ballot-summary {
    margin-left: 2em;

    @media screen and (min-width: base.$tablet) {
      margin-left: 0;
    }
  }
}

%header-spacing {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.25fr 0.75fr 20px;
  grid-template-columns: 1.25fr 0.75fr 20px;
  align-items: center;
  text-align: left;
  padding: 0 1rem;
  cursor: pointer;
  user-select: none;

  // IE11 does not automatically assign children to available columns, nth-child allows for manual assignment
  :nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row-align: center;
    min-width: 0;
  }

  :nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row-align: center;
  }

  :nth-child(3) {
    -ms-grid-column: 3;
    -ms-grid-row-align: center;
  }

  :nth-child(4) {
    -ms-grid-column: 4;
    -ms-grid-row-align: center;
  }

  @media screen and (min-width: 600px) {
    -ms-grid-columns: 1.25fr 1fr 1fr 20px;
    grid-template-columns: 1.25fr 1fr 1fr 20px;
    padding: 0 1rem;
  }
}

.past-ballot-list-header {
  @extend %header-spacing;

  display: none;
  text-transform: uppercase;
  margin-bottom: 1em;
  cursor: initial;

  @media screen and (min-width: base.$phone) {
    display: -ms-grid;
    display: grid;
  }
}

.past-ballot-trigger {
  @extend %header-spacing;

  background-color: #d6d6d6;
  color: #818181;
  font-size: 0.9em;
  margin-bottom: 0.15em;
  height: 2.5em;
  align-items: center;
}

.past-ballot-election-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1em;
}

.ballot-status,
.past-ballot-status {
  display: none;

  @media screen and (min-width: 600px) {
    display: -ms-grid;
    display: grid;
  }
}
