@use 'orig/partials/base';
@use 'orig/partials/fonts';
@use 'orig/partials/buttons';
@use 'orig/partials/layout';
@use 'orig/partials/modules';
@use 'orig/components/icons';
@use 'orig/components/collapsable';
@use 'orig/components/globalAlerts';
@use 'orig/components/navButtons';
@use 'orig/components/logoCounty';
@use 'orig/components/nav';
@use 'orig/components/loginForm';
@use 'orig/components/loginInformation';
@use 'orig/components/hero';
@use 'orig/components/footer';
@use 'orig/components/preferencesForm';
@use 'orig/components/voterProfile';
@use 'orig/components/currentBallot';
@use 'orig/components/appDownloadButton';
@use 'orig/components/pastBallot';
@use 'orig/components/loading';
@use 'orig/components/ambiguousForm';
@use 'orig/components/tokenForm';
@use 'orig/components/policyPage';
@use 'orig/components/faqPage';
@use 'orig/components/dashBoardPage';
@use 'orig/components/homePage';
@use 'orig/components/noBallots';
@use 'orig/components/preferencesPage';
@use 'orig/components/cards';
@use 'orig/components/tab-container';
@use 'orig/components/electedRepresentative';
@use 'orig/components/pollingLocations';
@use 'orig/components/modals';
@use 'orig/components/unsubscribe';
@use 'orig/components/confirmationEmail';
@use 'orig/components/deliveryConfirmationStatus';
@use 'orig/components/smsPolicy';
@use 'orig/components/pagination';
@use 'orig/components/releaseNotes';

.theme-orig {
  .theme3000 {
    display: none;
  }
  background-color: base.$secondary-color;
  font-family: base.$primary-font;
}

.theme-2018 {
  .nav-bar {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 10;
  }

  &.past-ballots-list {
    display: flex;
    flex-flow: column;
    padding: 0;
    background-color: white;
    min-height: 0;

    .past-ballot:last-child {
      border-bottom: none;
    }

    .past-ballot {
      display: flex;
      flex-flow: column;
      padding: 1em;
      border-bottom: 1px solid rgba(3, 49, 76, 0.1);

      .details {
        > p {
          padding: 0;
          margin: 0;
          border: none;
          font-size: 1em;
        }
        .name {
          font-weight: bold;
          text-align: left;
          text-transform: uppercase;
          color: base.$primary-color;
        }
        .date {
          margin-top: 0.25em;
        }
      }

      .status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em 0 0 0;
        min-width: 220px;

        &.accepted {
          color: #2e852e;
        }

        &.rejected {
          color: #e6210f;
        }

        &.incomplete {
          color: base.$text-gray;
        }
        > p {
          margin: 0;
        }
      }

      @media screen and (min-width: base.$largePhone) {
        flex-flow: row;
        justify-content: space-between;
        align-items: center;

        .status {
          justify-content: space-between;
          margin-left: 1em;
          padding: 0;
        }

        .details {
          width: 50%;
        }
      }
    }
  }

  .past-ballot-accepted {
    fill: #2e852e;
  }

  .past-ballot-rejected {
    fill: #e6210f;
  }

  .past-ballot-incomplete {
    fill: grey;
    opacity: 0.3;
  }

  .themeOrig {
    display: none;
  }
  background-color: white;

  font-family: base.$primary-font;
}

html,
body,
#root,
#root > div,
#app {
  //Because react-modal is overriding our theme class names, we are placing the modal styling
  //import inside #app; this might need to change as we begin to build out the new theme...
  //@use 'orig/components/modals';
  height: 100%;
}

#root {
  display: block;
}

body {
  background-color: #f0f0f0;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.screen-reader {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.skip-link {
  position: absolute;
  margin-top: 5em;
  padding: 0.6em;
  border-radius: 3px;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.8em;
  color: transparent;
  background-color: transparent;
  transition: ease-in-out, all 0.25s ease-in-out;
}

.skip-link:focus {
  color: #03314c;
  background-color: #f0f0f0;
}

.skip-link-logged-in {
  display: none;

  @media only screen and (min-width: base.$tablet) {
    display: block;
  }
}

@-moz-document url-prefix() {
  *:focus,
  button:focus,
  button[type='submit']:focus {
    outline: 2px rgb(77, 144, 254) auto;
    outline-offset: -2px;
  }
}
