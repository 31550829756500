@use '../partials/base';

.my-elected-officials {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  background-color: #fff;

  a {
    text-decoration: none;
    color: #245b75;

    &:visited {
      color: #245b75;
    }
  }

  .official-content {
    display: flex;
    flex-direction: column;

    hr {
      margin-top: 0;
      margin-bottom: 0;
      width: 95%;

      &:last-child {
        display: none;
      }
    }
  }

  .back-to-elected-representative {
    position: absolute;
    transform: rotate(180deg);
    cursor: pointer;
  }

  .elected-representative {
    padding: 0.5em;
    background-color: #fff;
    display: flex;
    color: base.$text-gray;
    cursor: pointer;
    flex-direction: row;

    .photo-details-container {
      display: flex;
      flex-grow: 1;

      @media only screen and (max-width: base.$phone) {
        flex-direction: column;
        align-items: center;
      }
      @media only screen and (min-width: base.$phone) {
        flex-direction: row;
      }

      .photo-container {
        flex: none;
        width: 10em;

        .photo {
          width: 66%;
          border-radius: 0.8em;
          margin-left: 1em;
        }
      }

      .details-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        text-align: center;
        margin: 0 1.3em;

        .name {
          font-size: 1.5em;
          font-weight: bold;
        }

        .title {
          font-size: 1.25em;
        }

        @media only screen and (min-width: base.$phone) {
          justify-content: center;
        }

        @media only screen and (max-width: base.$phone) {
          align-items: center;
          margin-left: 3em;

          .name {
            font-size: 1.5em;
          }

          .title {
            font-size: 1em;
          }
        }
      }
    }

    .more-details-arrow-container {
      width: 2em;
      align-self: center;
    }
  }

  .elected-representative-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5em 1em;

    a {
      text-decoration: none;
      color: #245b75;

      &:visited {
        color: #245b75;
      }
    }

    .photo {
      height: 16em;
      border-radius: 1em;
    }

    .details-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: larger;
      text-align: center;
      margin: 2em 1em;

      .urls {
        font-size: 2em;
      }

      .title {
        font-size: 1.5em;
      }

      .party {
        font-size: 1.5em;
        font-style: italic;
      }
    }

    .contact-info-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      @media only screen and (min-width: base.$phone) {
        flex-direction: row;
      }

      .contact-item-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0.8em 1em 0.8em;

        .contact-item {
          width: 3em;
          height: 3em;
          background-color: #0185bb;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
        }

        .contact-label {
          font-size: small;
          margin-top: 0.5em;
        }

        &.inactive {
          opacity: 0.1;
        }
      }

      .website,
      .email,
      .phones,
      .maps {
        font-size: 0.6em;
        padding-bottom: 0.2em;
        padding-top: 0.2em;
        display: flex;
        align-self: center;
      }

      .website {
        a {
          display: flex;
          align-items: center;
        }
      }
    }

    .social-container {
      align-items: center;
      display: flex;
      flex-direction: row;

      a {
        margin: 1em;
      }
    }
  }
}

.election-officials-loader {
  align-items: flex-start;
  margin-top: 2em;
}
