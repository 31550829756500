@use '../partials/base';

// Styles for Confirmation Email
.confirmation-login-page {
  height: 100%;
}

.confirmation-language-dropdown-container {
  display: flex;
  justify-content: flex-end;
  background-color: base.$primary-color;
  height: 4em;
  width: 100%;
  padding: 0.5em;

  @media only screen and (min-width: base.$largePhone) {
    margin-bottom: 0;
  }
}

.outer-confirmation-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0c3a58;
  height: 100%;

  .confirmation-blue-background {
    background-color: #f0f0f0;
    max-width: none;
    height: 100%;
    border: solid 4px #f0f0f0;
  }

  .confirmation-login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .confirmation-logo-container {
    display: flex;
    justify-content: center;
    background-color: #0c3a58;
    width: 100%;
    padding: 0.5em 1em;
    margin-bottom: 0.5em;
  }

  .account-logo {
    width: auto;
    max-width: 280px;
    max-height: 300px;
  }

  .confirmation-login-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 0.5em 2em;
    background-color: #f0f0f0;
  }

  .confirmation-text {
    color: #767676;
    font-size: 13px;
    font-style: italic;
    text-align: left;
    width: 100%;
  }

  .confirmation-input {
    width: 100%;
  }

  .confirmation-error-text {
    color: base.$error-red;
  }

  .confirmation-example {
    color: #767676;
    font-size: 14px;
    margin-top: 1em;
    text-align: left;
  }

  .confirmation-label {
    color: #0b314d;
    font-size: 16px;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .login-button {
    margin-top: 1em;
    padding: 1em;
    width: 100%;
  }
}

@media only screen and (min-width: base.$largePhone) {
  .outer-confirmation-login-container {
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 12% 12%;
    background-color: #f0f0f0;

    .confirmation-blue-background {
      background-color: #0c3a58;
      max-width: 450px;
      height: auto;
      margin-top: auto;
      margin-bottom: auto;
    }

    .confirmation-logo-container {
      margin-top: 0.5em;
    }

    .confirmation-login-container {
      margin: 0 1em 1em 1em;
      padding: 1em;
    }
  }
}

.confirmation_resend_link {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  text-transform: lowercase;
  size: 1em;
  margin-top: 2em;
  width: 100%;
  text-align: right;
}

.confirm-email-loader {
  height: 100%;
  align-items: center;
}

.confirm-email-voter-name {
  color: white;
}

.confirm-email-language-dropdown {
  align-items: center;
}
