@use '../partials/base';
@use '../components/languageDropdown';

.nav-bar-buttons {
  display: flex;
  height: 100%;
  margin-left: 1em;
}

.spacer {
  height: 60%;
  margin: auto 1em;
  border-left: 2px solid white;
  opacity: 0.2;
}

.voter-profile-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: base.$primary-color;
  color: base.$secondary-color;
  padding: 0;

  span {
    display: none;
    padding-right: 0.7em;
  }

  .nav-bar-dropdown-icon {
    display: none;
  }

  @media only screen and (min-width: base.$tablet) {
    span {
      display: inline-flex;
    }

    .nav-bar-dropdown-icon {
      display: inline-flex;
    }
  }
}

.voter-profile-btn-icon {
  display: inline-flex;
  width: 30px;

  @media only screen and (min-width: base.$tablet) {
    display: none;
  }
}

.nav-bar-dropdown-icon {
  opacity: 0.5;
  width: 10px;
  height: 10px;
}

.nav-bar-buttons > .logout-button {
  align-self: center;
  cursor: pointer;
  border: none;
  background-color: base.$primary-color;
  color: base.$secondary-color;
  display: none;

  @media only screen and (min-width: base.$tablet) {
    display: inline-flex;
  }
}

.logout-spacer {
  display: none;

  @media only screen and (min-width: base.$tablet) {
    display: inline-flex;
  }
}
