@use '../partials/base';

.polling-locations {
  display: flex;
  background-color: white;
  flex-direction: column;

  @media only screen and (min-width: base.$phone) {
    flex-direction: row;
  }

  hr.content-divider:last-child {
    display: none;
  }

  .dashboard-card {
    box-shadow: unset;
  }

  .search-container {
    flex: 1 0;
  }

  .search {
    flex: 1 0;
    color: #6f6f6d;
    padding: 1em;

    .title {
      margin-bottom: 0.5em;
    }

    .search-input {
      margin-bottom: 1em;
      position: relative;
      width: 100%;

      .icon {
        float: left;
        position: absolute;
        z-index: 1;
      }

      input {
        padding-top: 2px;
        padding-left: 14px;
        margin-top: -2px;
        width: 100%;
        border-style: none;
        border-bottom: 1px solid #6f6f6d;
        background-color: transparent;
      }
    }

    .search-result {
      display: flex;
      flex-direction: column;
      gap: 0.5em;

      .polling-type {
        margin-top: 0.5em;

        .polling-location-type-header {
          margin-bottom: 0.8em;
          display: flex;
          gap: 0.5em;
          align-items: center;
          font-size: 1.2em;
        }

        .polling-locations-container {
          min-height: unset;
          padding: 0.5em;
          margin-bottom: 0.2em;

          .polling-location {
            display: flex;

            .polling-label {
              width: 2em;
              margin-left: 0;
              position: relative;

              .label {
                position: absolute;
                width: 100%;
                text-align: center;
                color: white;
                font-size: 0.8em;
              }

              .marker {
                display: block;
                margin-left: auto;
                margin-right: auto;
              }
            }

            .polling-address {
              .address-line {
                text-transform: capitalize;

                &.location-name {
                  font-weight: bold;
                }
              }

              .directions-link {
                margin: 1em 0 1em 0;
                display: block;
                text-decoration: none;

                &:visited {
                  color: blue;
                }
              }
            }
          }
        }
      }
    }
  }

  .map {
    flex: 1 0;
    overflow-x: hidden;
  }
}
