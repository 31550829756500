@use '../partials/base';

@mixin dropdown-style {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #c8c8c8;
  font-size: 0.9em;
  padding: 0.5em;
}

.form-layout {
  padding: 3em 1em;
  background-color: white;
  color: base.$text-gray;
}

.preference-section {
  padding-bottom: 4em;
}

.preference-section-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;

  @media only screen and (min-width: base.$tablet) {
    margin-bottom: 2em;
  }
}

.preference-title-section {
  display: flex;

  svg {
    min-width: 22px;
  }
  .preference-title {
    padding: 0 1.25em;
  }
}

.contact-language-section {
  .preference-title-section {
    margin-bottom: 2em;
  }
}

.dropdown {
  @include dropdown-style;
}
.contact-language-dropdown {
  @include dropdown-style;
}

.toggle {
  display: flex;
}

// INPUT FIELD

.input-section {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  width: 100%;
  height: 3.25em;

  div {
    display: flex;
    align-items: flex-end;
  }
}

.preference-label {
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 1em;

  &.email-preference-label {
    margin-bottom: 0;
  }
}

.preference-input {
  width: 100%;
  border-style: none;
  border-bottom: 1px solid base.$text-gray;
}

.clear-field {
  color: base.$text-gray;
  font-size: 1.2em;
  border-style: none;
  border-bottom: 1px solid base.$text-gray;
  background-color: white;
}

.preference-title {
  text-transform: uppercase;
  margin-top: 0.1em;
}

// TIME FIELD

.timeTo {
  text-transform: uppercase;
  padding: 0 1.5em;
}

.time-input-section {
  display: flex;
  padding-top: 1.25em;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.time-field {
  display: flex;
  align-items: center;
  width: 48%;
}

.time-input-field {
  @include dropdown-style;
}

.button-layout {
  display: flex;
  justify-content: center;
  background-color: white;
  width: 100%;
}

%prefs-button {
  width: 48%;
  cursor: pointer;
  padding: 1em;
  font-size: 0.8em;
  text-align: center;

  @media only screen and (min-width: base.$phone) {
    font-size: 1em;
  }

  @media only screen and (min-width: 520px) {
    width: 13em;
  }
}

.submit-button {
  @extend %prefs-button;
}

.reset-button {
  @extend %prefs-button;
  margin-right: 1em;
}

.opt-switch {
  border: none;
  padding: 0;
}

.recaptcha-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
  width: 100%;
  @media (max-width: base.$phone) {
    margin-top: 0.5em;
  }
}

.email-information-section {
  margin-top: 1em;
}
