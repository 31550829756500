@use '../partials/base';

.release-notes-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 92%;
  margin-right: 1em;
  margin-left: 1em;

  .description {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  .pagination-description {
    font-size: 0.8em;
    font-style: italic;
    margin-top: 0.5em;
  }

  .release-notes-list {
    overflow: auto;
    padding-left: 1em;
    height: 82%;
    margin-bottom: 0;
  }

  .release-notes-li {
    display: flex;
    margin-bottom: 1em;
    line-height: 1.5em;
    list-style: none;
  }

  .note {
    white-space: pre-wrap;
  }

  .published-note {
    margin-right: 1em;
  }

  .release-notes-pagination {
    margin-top: 0;
  }
}

.release-notes-loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
