@use '../partials/base';
@use '../partials/layout';

.support-notice {
  opacity: 0.85;
  display: flex;
  align-items: center;
  background-color: rgba(3, 49, 76, 0.05);
  border-bottom: 2px solid rgba(3, 49, 76, 0.15);
  padding: 1em;
  color: base.$primary-color;
  max-width: 35em;
  border-radius: 6px;
  text-align: left;
  margin: 0 auto;

  svg {
    margin-right: 1em;
    fill: base.$primary-color;
  }

  p {
    margin: 0;
    font-size: 0.9em;
    @media (max-width: base.$phone) {
      font-size: 0.8em;
    }
  }

  a {
    white-space: nowrap;
    cursor: pointer;
  }

  @media (max-width: base.$phone) {
    max-width: none;
  }

  @media screen and (min-width: base.$desktop) {
    margin: auto 0 0 0;
    flex: 0 0 auto;
  }
}

footer {
  font-size: 0.75em;
  color: base.$text-gray;
  text-align: center;
  margin-top: 3em;

  @media (max-width: base.$phone) {
    margin-top: 1em;
  }

  @media screen and (min-width: base.$tablet) {
    margin-bottom: 1.5em;
  }

  @media screen and (min-width: base.$desktop) {
    flex: 0 0 auto;
  }
}

.modal-link {
  cursor: pointer;
}

footer .logout-button {
  color: base.$text-gray;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
