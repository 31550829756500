@use '../partials/base';

.sms-policy-list {
  padding-right: 2.5em;
}

.sms-policy-li {
  margin-bottom: 1em;
  font-size: 0.9em;
}

.sms-link-button {
  color: blue;
  cursor: pointer;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  font-style: italic;
  font-size: 0.9em;
  font-weight: 300;
}
