@use '../partials/base';

.home-page-layout {
  display: flex;
  flex-flow: column;
  background-color: white;

  @media only screen and (min-width: base.$desktop) {
    flex-direction: row;
    min-height: calc(100vh - 4em);
  }
}

.login-container {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  padding: 1em 1em 1.5em 1em;
  width: 100%;

  color: rgba(26, 39, 85, 0.5);
  background-color: white;

  @media only screen and (min-width: base.$tablet) {
    padding: 1em 4em 1em 4em;
  }

  @media only screen and (min-width: base.$desktop) {
    display: flex;
    justify-content: normal;
    padding: 1em 1.5em 0 1.5em;
    width: 35%;
    height: calc(100vh - 4em);
    overflow-y: auto;
  }

  @media only screen and (min-width: base.$xlarge) {
    width: 30%;
  }

  @media only screen and (min-width: base.$high-res) {
    width: 25%;
  }
}
