@import url("https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i");
.secondary-button, .primary-button {
  padding: 1.25em 1em;
  border: none;
  letter-spacing: 2px;
  font-size: 1em;
  text-transform: uppercase;
  cursor: pointer;
}

.primary-button {
  background-color: #03314c;
  color: #f0f0f0;
}

.secondary-button {
  background-color: #f0f0f0;
  color: black;
}

.preferences-submit-button {
  width: 20%;
}

.reset-preferences-button {
  width: 20%;
}

.page-layout {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .page-layout {
    margin: 0 10%;
  }
}
@media only screen and (min-width: 992px) {
  .page-layout {
    margin: 0 11%;
  }
}
@media only screen and (min-width: 1200px) {
  .page-layout {
    margin: 0 8%;
  }
}
@media only screen and (min-width: 1800px) {
  .page-layout {
    margin: 0 25%;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%;
  }
}
.error, .field-message {
  padding-top: 1em;
  font-style: italic;
  font-size: 0.9em;
  font-weight: 300;
}

.field-message {
  color: #696969;
}
@media (max-width: 425px) {
  .field-message {
    margin-bottom: 1em;
  }
}

.error {
  color: #b50000;
  display: block;
}

.svg-icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  fill: #75797e;
}

.nav-globe-icon {
  fill: white;
  margin-right: 0.25em;
}

.nav-carat-icon {
  fill: white;
  opacity: 0.5;
}

.loading-icon {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.main-loading-icon {
  width: 45px;
  height: 45px;
}

.global-alert-icon {
  display: none;
  align-self: center;
  width: 35px;
  height: 35px;
  margin-left: 2em;
}
@media only screen and (min-width: 992px) {
  .global-alert-icon {
    display: inline;
  }
}

.social-icon {
  border-radius: 50%;
  fill-rule: evenodd;
  border-style: solid;
  border-width: 0.1em;
}
.social-icon .social-svg-background {
  fill: transparent;
}
.social-icon .social-svg-mask {
  fill: transparent;
}
.social-icon.facebook {
  border-color: #3b5998;
}
.social-icon.facebook .social-svg-icon {
  fill: #3b5998;
  transform: scale(1.3) translate(-0.5em, -0.5em);
}
.social-icon.twitter {
  border-color: #00aced;
}
.social-icon.twitter .social-svg-icon {
  fill: #00aced;
  transform: scale(1.3) translate(-0.5em, -0.5em);
}
.social-icon.twitter-reverse {
  border-color: #00aced;
}
.social-icon.twitter-reverse .social-svg-background,
.social-icon.twitter-reverse .social-svg-icon {
  fill: transparent;
}
.social-icon.twitter-reverse .social-svg-mask {
  fill: #00aced;
}
.social-icon.youtube {
  border-color: #ff3333;
}
.social-icon.youtube .social-svg-icon {
  fill: #ff3333;
  transform: scale(1.5) translate(-0.6em, -0.6em);
}

.map-marker-icon {
  fill: #ff0000;
}
.map-marker-icon.grey {
  fill: #f0f0f0;
}

.dashboard-collapsable {
  margin-top: 2.5em;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .dashboard-collapsable {
    margin-top: 3.5em;
  }
}

.collapsable-header {
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  color: #03314c;
  width: 100%;
  padding: 0.5em 0;
  font-size: 1.25em;
  cursor: pointer;
  user-select: none;
  background-color: white;
  transition: background-color 0.5s ease-out;
}
@media only screen and (min-width: 768px) {
  .collapsable-header {
    font-size: 1.6em;
    padding: 0.75em 0;
  }
}
.collapsable-header img {
  width: 35px !important;
  height: 35px;
  transition: transform 0.3s ease-out;
}

.header-opened {
  background-color: rgba(255, 255, 255, 0);
}
.header-opened img {
  transform: rotate(-180deg);
}

.header-closed {
  background-color: white;
}
.header-closed img {
  transform: rotate(0deg);
}

.header-text {
  justify-self: stretch;
  margin-right: 0.5em;
}

.global-alerts {
  height: 0;
  overflow: visible;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;
}

.alert-wrapper {
  outline: none;
}

.global-alert {
  display: flex;
  justify-content: space-between;
  color: white;
  user-select: none;
  cursor: default;
}
.global-alert a {
  color: white;
}

.close-global-alert-btn {
  color: rgba(0, 0, 0, 0.3);
  font-size: 1.2em;
  background-color: rgba(255, 255, 255, 0.3);
  border: 0;
  padding: 0;
  margin-left: 1em;
  min-width: 2em;
  cursor: pointer;
}
.close-global-alert-btn:hover {
  background-color: rgba(255, 255, 255, 0.4);
  color: rgba(0, 0, 0, 0.5);
}
@media only screen and (min-width: 768px) {
  .close-global-alert-btn {
    min-width: 2.5em;
    margin-left: 1.5em;
  }
}
@media only screen and (min-width: 1200px) {
  .close-global-alert-btn {
    min-width: 3em;
  }
}

.global-alert-content {
  display: flex;
  flex-flow: column;
  padding: 0.75em 0 0.75em 1em;
  width: 100%;
  user-select: text;
}
@media only screen and (min-width: 992px) {
  .global-alert-content {
    padding: 1em 0 1em 3em;
  }
}

.global-alert-label, .global-alert-message {
  width: 100%;
  margin-bottom: 1em;
}
.global-alert-label:first-child, .global-alert-message:first-child {
  text-align: left;
}
.global-alert-label:last-of-type, .global-alert-message:last-of-type {
  margin-bottom: 0;
}

.global-alert-label {
  margin-bottom: 1rem;
}

.error-alert {
  background-color: #f13827;
}

.notice-alert {
  background-color: #fdb903;
}

.success-alert {
  background-color: #6acc6a;
}

.contact-preferences-alert {
  min-width: 280px;
  background-color: #aa1209;
  padding: 0.75em 1em 0.75em 1em;
  display: flex;
  color: white;
  user-select: none;
  cursor: default;
}
.contact-preferences-alert .contact-preferences-alert-icon {
  margin-right: 12px;
}
.contact-preferences-alert a {
  color: white;
}
@media only screen and (min-width: 768px) {
  .contact-preferences-alert {
    display: none;
  }
}

.language-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}
.language-dropdown .nav-globe-icon {
  display: none;
}
@media screen and (min-width: 992px) {
  .language-dropdown .nav-globe-icon {
    display: inline-block;
    margin-right: 0.6em;
  }
}
.language-dropdown select {
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.4em 2em 0.3em 0.8em;
  width: 9em;
  margin: 0;
  border-radius: 3px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  text-transform: capitalize;
}

.nav-bar-buttons {
  display: flex;
  height: 100%;
  margin-left: 1em;
}

.spacer {
  height: 60%;
  margin: auto 1em;
  border-left: 2px solid white;
  opacity: 0.2;
}

.voter-profile-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: #03314c;
  color: #f0f0f0;
  padding: 0;
}
.voter-profile-button span {
  display: none;
  padding-right: 0.7em;
}
.voter-profile-button .nav-bar-dropdown-icon {
  display: none;
}
@media only screen and (min-width: 768px) {
  .voter-profile-button span {
    display: inline-flex;
  }
  .voter-profile-button .nav-bar-dropdown-icon {
    display: inline-flex;
  }
}

.voter-profile-btn-icon {
  display: inline-flex;
  width: 30px;
}
@media only screen and (min-width: 768px) {
  .voter-profile-btn-icon {
    display: none;
  }
}

.nav-bar-dropdown-icon {
  opacity: 0.5;
  width: 10px;
  height: 10px;
}

.nav-bar-buttons > .logout-button {
  align-self: center;
  cursor: pointer;
  border: none;
  background-color: #03314c;
  color: #f0f0f0;
  display: none;
}
@media only screen and (min-width: 768px) {
  .nav-bar-buttons > .logout-button {
    display: inline-flex;
  }
}

.logout-spacer {
  display: none;
}
@media only screen and (min-width: 768px) {
  .logout-spacer {
    display: inline-flex;
  }
}

.logo-county {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 1.1em;
  letter-spacing: 0.03em;
}
.logo-county .logo-container {
  position: relative;
  top: 0.15em;
  text-decoration: none;
  color: white;
  padding: 0;
  margin: 0;
}
.logo-county .logo-container img {
  display: none;
  position: relative;
  top: -4px;
}
@media only screen and (min-width: 768px) {
  .logo-county .logo-container img {
    display: initial;
  }
}
.logo-county .logo-county-spacer {
  display: none;
}
@media only screen and (min-width: 768px) {
  .logo-county .logo-county-spacer {
    display: initial;
  }
}

.nav-bar {
  background-color: #03314c;
  display: flex;
  width: 100%;
  height: 4em;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0 1.25em;
}
@media only screen and (min-width: 768px) {
  .nav-bar {
    padding: 0 10%;
  }
}
@media only screen and (min-width: 992px) {
  .nav-bar {
    padding: 0 1.5em 0 2em;
  }
}

.nav-bar-dropdown-icon {
  opacity: 0.5;
  width: 10px;
  height: 10px;
}

@media screen and (min-width: 992px) {
  .login-form {
    flex: 1 0 auto;
    margin: 0 0 3em 0;
  }
}
.login-form .form-row {
  display: flex;
  flex-flow: column;
}
.login-form .form-row label {
  display: block;
  font-size: 1em;
  color: #03314c;
  margin: 2.5em 0 0.5em 0;
}
@media (max-width: 425px) {
  .login-form .form-row label {
    margin: 0.5em 0 0.5em;
  }
}
.login-form .form-row label:first-of-type {
  margin-top: 3.5em;
}
@media (max-width: 425px) {
  .login-form .form-row label:first-of-type {
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) {
  .login-form .form-row {
    flex-flow: row;
    justify-content: space-between;
  }
  .login-form .form-row label {
    font-size: 1.15em;
    margin: 2.5em 0 1em 0;
  }
  .login-form .form-row section {
    width: 47%;
  }
}
@media screen and (min-width: 992px) {
  .login-form .form-row {
    flex-flow: column;
    justify-content: space-between;
  }
  .login-form .form-row label {
    font-size: 1em;
    margin: 1.75em 0 0.8rem 0 !important;
  }
  .login-form .form-row section {
    width: 100%;
  }
}
@media (max-width: 425px) {
  .login-form .form-row {
    margin-bottom: 0.5em;
  }
}
.login-form section input {
  margin-bottom: 0.3em;
  width: 100%;
  border: none;
  border-bottom: 1px solid grey;
  font-size: 1.1em;
}
@media screen and (min-width: 992px) {
  .login-form section input {
    font-size: 1em;
    margin-bottom: 0;
  }
}
.login-form input::placeholder {
  color: #c0c0c0;
}
.login-form .field-message {
  padding: 0.8em 0 0 0;
  font-style: italic;
  color: #696969;
}
.login-form .error {
  font-style: italic;
  color: #b50000;
}
.login-form .form-buttons {
  display: flex;
  justify-content: center;
}
.login-form .login-button {
  text-align: center;
  border: none;
  border-radius: 3px;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #03314c;
  color: white;
  padding: 0.8em;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}
@media only screen and (min-width: 768px) {
  .login-form .login-button {
    margin-top: 3.5em;
    margin-bottom: 3.5em;
    padding: 1em;
    width: 60%;
  }
}
@media only screen and (min-width: 992px) {
  .login-form .login-button {
    margin-top: 2.5em;
    margin-bottom: 0;
    width: 100%;
  }
}

.login-loading {
  height: 100%;
  display: flex;
  align-items: center;
}
.login-loading > div {
  background-color: white;
}

.qr-login-page {
  height: 100%;
}

.qr-language-dropdown-container {
  display: flex;
  justify-content: flex-end;
  background-color: #03314c;
  height: 4em;
  width: 100%;
  padding: 0.5em;
}
@media only screen and (min-width: 500px) {
  .qr-language-dropdown-container {
    margin-bottom: 0;
  }
}

.outer-qr-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0c3a58;
  height: 100%;
}
.outer-qr-login-container .qr-blue-background {
  background-color: #f0f0f0;
  max-width: none;
  height: 100%;
  border: solid 4px #f0f0f0;
}
.outer-qr-login-container .qr-login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}
.outer-qr-login-container .qr-logo-container {
  display: flex;
  justify-content: center;
  background-color: #0c3a58;
  width: 100%;
  padding: 0.5em 1em;
  margin-bottom: 0.5em;
}
.outer-qr-login-container .account-logo {
  width: auto;
  max-width: 280px;
  max-height: 300px;
}
.outer-qr-login-container .qr-login-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0.5em 2em;
  background-color: #f0f0f0;
}
.outer-qr-login-container .dob-text {
  color: #767676;
  font-size: 13px;
  font-style: italic;
  text-align: left;
  width: 100%;
}
.outer-qr-login-container .dob-input {
  width: 100%;
}
.outer-qr-login-container .qr-error-text {
  color: #d45659;
}
.outer-qr-login-container .dob-example {
  color: #767676;
  font-size: 14px;
  margin-top: 1em;
  text-align: left;
}
.outer-qr-login-container .dob-label {
  color: #0b314d;
  font-size: 16px;
  margin-top: 1em;
  margin-bottom: 1em;
}
.outer-qr-login-container .login-button {
  margin-top: 1em;
  padding: 1em;
  width: 100%;
}

@media only screen and (min-width: 500px) {
  .outer-qr-login-container {
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 12% 12%;
    background-color: #f0f0f0;
  }
  .outer-qr-login-container .qr-blue-background {
    background-color: #0c3a58;
    max-width: 450px;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  .outer-qr-login-container .qr-logo-container {
    margin-top: 0.5em;
  }
  .outer-qr-login-container .qr-login-container {
    margin: 0 1em 1em 1em;
    padding: 1em;
  }
}
.login-information {
  display: flex;
  flex-direction: column;
  font-size: 0.9em;
}
.login-information .get-started {
  color: #03314c;
  font-size: 1.6em;
  text-transform: uppercase;
  font-weight: bold;
  font-style: initial;
  margin-bottom: 1.5rem;
}
.login-information .registration-notice,
.login-information .branding-blurb {
  font-style: italic;
}
.login-information .registration-notice {
  color: #696969;
  padding: 0.25em 0 0.25em 1em;
  border-left: rgba(26, 39, 85, 0.8) 3px solid;
}
.login-information .registration-notice p {
  margin: 0;
}
.login-information .registration-notice .faq-link,
.login-information .registration-notice .wmb-info {
  margin-top: 1em;
}
@media only screen and (min-width: 992px) {
  .login-information {
    margin: 2em 0 0 0;
    flex: 1 0 auto;
  }
  .login-information .get-started {
    text-align: center;
  }
}
@media (max-width: 425px) {
  .login-information {
    font-size: 0.8em;
    margin-bottom: 0.5em;
  }
}

.hero {
  display: flex;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 12% 12%;
  background-color: white;
}
@media only screen and (min-width: 992px) {
  .hero {
    flex-basis: 0;
    flex-grow: 1;
  }
}

.hero-content {
  display: flex;
  flex-flow: column;
  align-items: center;
  align-self: center;
  padding: 1.5em 1em;
  background-color: rgba(0, 48, 80, 0.95);
  border: solid 4px #ffffff;
  color: #fff;
  text-align: center;
}
@media (max-width: 425px) {
  .hero-content {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .hero-content {
    flex-direction: row;
    width: 80%;
    margin: 5em 1em 8em 1em;
    padding: 2em;
    max-width: 40em;
    text-align: left;
  }
}
@media only screen and (min-width: 992px) {
  .hero-content {
    margin: auto 0;
    position: relative;
    top: -1.5em;
    max-width: 38em;
  }
}

.logo {
  flex-shrink: 0;
  max-width: 13em;
}
@media screen and (min-width: 768px) {
  .logo {
    max-width: 14em;
  }
}
@media (max-width: 425px) {
  .logo {
    flex-shrink: 1;
    max-width: 9em;
  }
}

.line {
  display: none;
}
@media only screen and (min-width: 768px) {
  .line {
    display: inline-block;
    border-left: 2px solid #fff;
    height: 9.5em;
    padding-right: 1.5em;
    margin-left: 1.5em;
  }
}

.header-container {
  width: 100% !important;
}
@media only screen and (min-width: 768px) {
  .header-container {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: flex-start;
  }
  .header-container > div {
    margin: 0 0 1rem 0;
  }
  .header-container > p {
    text-align: left;
  }
}

.hero-header {
  width: 100% !important;
  font-size: 1.8em;
  margin: 1em 0 0.6em 0;
  letter-spacing: 0.04em;
}
@media (max-width: 425px), (max-width: 768px) {
  .hero-header {
    font-size: 1.2em;
  }
}

.hero-sub-header {
  width: 100% !important;
  font-weight: 300;
  text-align: center;
  margin: 0;
  letter-spacing: 0.02em;
  line-height: 1.25em;
  font-size: 1.1em;
}
@media (max-width: 425px), (max-width: 768px) {
  .hero-sub-header {
    font-size: 0.8em;
  }
}

.support-notice {
  opacity: 0.85;
  display: flex;
  align-items: center;
  background-color: rgba(3, 49, 76, 0.05);
  border-bottom: 2px solid rgba(3, 49, 76, 0.15);
  padding: 1em;
  color: #03314c;
  max-width: 35em;
  border-radius: 6px;
  text-align: left;
  margin: 0 auto;
}
.support-notice svg {
  margin-right: 1em;
  fill: #03314c;
}
.support-notice p {
  margin: 0;
  font-size: 0.9em;
}
@media (max-width: 425px) {
  .support-notice p {
    font-size: 0.8em;
  }
}
.support-notice a {
  white-space: nowrap;
  cursor: pointer;
}
@media (max-width: 425px) {
  .support-notice {
    max-width: none;
  }
}
@media screen and (min-width: 992px) {
  .support-notice {
    margin: auto 0 0 0;
    flex: 0 0 auto;
  }
}

footer {
  font-size: 0.75em;
  color: #6f6f6d;
  text-align: center;
  margin-top: 3em;
}
@media (max-width: 425px) {
  footer {
    margin-top: 1em;
  }
}
@media screen and (min-width: 768px) {
  footer {
    margin-bottom: 1.5em;
  }
}
@media screen and (min-width: 992px) {
  footer {
    flex: 0 0 auto;
  }
}

.modal-link {
  cursor: pointer;
}

footer .logout-button {
  color: #6f6f6d;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.form-layout {
  padding: 3em 1em;
  background-color: white;
  color: #6f6f6d;
}

.preference-section {
  padding-bottom: 4em;
}

.preference-section-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
}
@media only screen and (min-width: 768px) {
  .preference-section-header {
    margin-bottom: 2em;
  }
}

.preference-title-section {
  display: flex;
}
.preference-title-section svg {
  min-width: 22px;
}
.preference-title-section .preference-title {
  padding: 0 1.25em;
}

.contact-language-section .preference-title-section {
  margin-bottom: 2em;
}

.dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #c8c8c8;
  font-size: 0.9em;
  padding: 0.5em;
}

.contact-language-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #c8c8c8;
  font-size: 0.9em;
  padding: 0.5em;
}

.toggle {
  display: flex;
}

.input-section {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  width: 100%;
  height: 3.25em;
}
.input-section div {
  display: flex;
  align-items: flex-end;
}

.preference-label {
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 1em;
}
.preference-label.email-preference-label {
  margin-bottom: 0;
}

.preference-input {
  width: 100%;
  border-style: none;
  border-bottom: 1px solid #6f6f6d;
}

.clear-field {
  color: #6f6f6d;
  font-size: 1.2em;
  border-style: none;
  border-bottom: 1px solid #6f6f6d;
  background-color: white;
}

.preference-title {
  text-transform: uppercase;
  margin-top: 0.1em;
}

.timeTo {
  text-transform: uppercase;
  padding: 0 1.5em;
}

.time-input-section {
  display: flex;
  padding-top: 1.25em;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.time-field {
  display: flex;
  align-items: center;
  width: 48%;
}

.time-input-field {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #c8c8c8;
  font-size: 0.9em;
  padding: 0.5em;
}

.button-layout {
  display: flex;
  justify-content: center;
  background-color: white;
  width: 100%;
}

.reset-button, .submit-button {
  width: 48%;
  cursor: pointer;
  padding: 1em;
  font-size: 0.8em;
  text-align: center;
}
@media only screen and (min-width: 425px) {
  .reset-button, .submit-button {
    font-size: 1em;
  }
}
@media only screen and (min-width: 520px) {
  .reset-button, .submit-button {
    width: 13em;
  }
}

.reset-button {
  margin-right: 1em;
}

.opt-switch {
  border: none;
  padding: 0;
}

.recaptcha-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
  width: 100%;
}
@media (max-width: 425px) {
  .recaptcha-section {
    margin-top: 0.5em;
  }
}

.email-information-section {
  margin-top: 1em;
}

.voter-profile {
  width: 100%;
  position: absolute;
  z-index: 10;
  padding: 1.5em;
  background-color: white;
  box-shadow: 1px 0 6px #d1d1d1;
  display: block;
}
.voter-profile.mobile {
  display: none;
}
@media only screen and (max-width: 360px) {
  .voter-profile {
    display: none;
  }
  .voter-profile.mobile {
    padding: 0;
    display: block;
  }
  .voter-profile.mobile button {
    width: 100%;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
}
@media only screen and (min-width: 768px) {
  .voter-profile {
    padding: 3em;
  }
}
@media only screen and (min-width: 1200px) {
  .voter-profile {
    padding: 3em 15%;
  }
}
.voter-profile header {
  line-height: 1.2;
  font-size: 1.75em;
  color: #002840;
  padding-bottom: 1em;
  margin: 0;
}
@media only screen and (min-width: 425px) {
  .voter-profile header {
    padding-bottom: 0.5em;
  }
}
.voter-profile button {
  display: block;
  margin: 0 auto;
  width: 14em;
  height: 3.1em;
  background-color: #002840;
  border: none;
  color: #ffffff;
  text-transform: uppercase;
}

.voter-profile-content {
  width: 100%;
  display: block;
  margin-bottom: 4em;
}
@media only screen and (min-width: 768px) {
  .voter-profile-content {
    display: flex;
    justify-content: space-between;
  }
}

.voter-profile-county-logo {
  display: none;
  max-width: 18em;
}
@media only screen and (min-width: 768px) {
  .voter-profile-county-logo {
    display: inline-block;
    width: 30%;
  }
}
.voter-profile-county-logo img {
  display: block;
  width: 100%;
  height: auto;
}

.voter-profile-ballottrax-description {
  font-size: 0.9em;
  line-height: 1.37;
  letter-spacing: 0.06em;
  margin: 0;
  padding-bottom: 2.5em;
  color: #989695;
}
@media only screen and (min-width: 768px) {
  .voter-profile-ballottrax-description {
    padding-bottom: 3.5em;
  }
}

.voter-profile-information {
  letter-spacing: 0.05em;
  margin-bottom: 3em;
  font-size: 1em;
}
@media only screen and (min-width: 768px) {
  .voter-profile-information {
    max-width: 30%;
    margin-bottom: 0;
  }
}
.voter-profile-information p {
  padding: 0;
  margin: 0;
  color: #818181;
  font-size: 0.9em;
}
.voter-profile-information .sub-header {
  padding: 0 0 1em 0;
  margin: 0;
  color: #4a4c4f;
  font-size: 1em;
  text-transform: uppercase;
}

.current-ballot-title {
  color: #03314c;
  font-size: 1.3em;
  padding: 1.5em 1em;
  text-align: center;
}
@media screen and (min-width: 425px) {
  .current-ballot-title {
    font-size: 1.5em;
  }
}

.current-ballot {
  background-color: white;
  display: grid;
  display: -ms-grid;
  justify-items: center;
  padding: 1em 1.5em 2em 1.5em;
  -ms-grid-columns: 1fr 3fr;
}
@media screen and (min-width: 1200px) {
  .current-ballot {
    grid-template-columns: 1fr 3fr;
  }
}

.progress-graphic {
  -ms-grid-column: 1;
}
@media screen and (min-width: 425px) {
  .progress-graphic {
    width: 14em;
    height: 14em;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .progress-graphic {
    width: 100% !important;
  }

  .halted-graphic,
.caution-graphic {
    height: 45%;
    margin: 2.5em 0.5em !important;
    padding: 0.7em 0 !important;
  }
}
@supports (-ms-ime-align: auto) {
  .progress-graphic {
    width: 100% !important;
  }

  .halted-graphic,
.caution-graphic {
    height: 35%;
    margin: 3.5em 1em !important;
    padding: 1em 0 !important;
    font-size: 1em !important;
  }
}
.halted-graphic {
  background-color: red;
  margin: 1.5em 0;
  padding: 0.5em 1.5em;
  color: white;
  font-size: 1.5em;
  display: inline-block;
  align-self: center;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.3;
}
@media screen and (min-width: 1200px) {
  .halted-graphic {
    margin: 0 2em 0 1em;
  }
}

.caution-graphic {
  background-color: #ffca2b;
  margin: 1.5em 0;
  padding: 0.5em 1.5em;
  color: white;
  font-size: 1.5em;
  display: inline-block;
  align-self: center;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.3;
}
@media screen and (min-width: 1200px) {
  .caution-graphic {
    margin: 0 2em 0 1em;
  }
}

.current-ballot-status {
  color: #6f6f6d;
  -ms-grid-column: 2;
  -ms-grid-column-span: 2;
  width: 100%;
}

.election-name {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.1em;
  color: #03314c;
  border-bottom: 0.5px solid #f0f0f0;
  padding-bottom: 1em;
  padding-top: 0.8em;
}
@media screen and (min-width: 425px) {
  .election-name {
    text-align: left;
  }
}
@media screen and (min-width: 1200px) {
  .election-name {
    grid-column: 1/span 2;
    -ms-grid-column: 1/span 2;
  }
}

.expected-delivery-date {
  font-size: 0.9em;
}

.ballot-path {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row: 3;
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .ballot-path {
    grid-column: 1/span 2;
    grid-row: 3;
  }
}

.ballot-step-wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 0.9em;
}

.ballot-step-wrapper .step-style {
  color: #767574;
}

.ballot-step-wrapper.future .step-style {
  color: #bdbdbe;
}

.ballot-step-wrapper.future .no-future {
  display: none;
}

.ballot-step-wrapper .only-future {
  display: none;
}

.ballot-step-wrapper.future .only-future {
  display: unset;
}

.ballot-summary {
  margin-top: 1em;
  margin-bottom: 0.5em;
  padding-bottom: 0.4em;
}

.ballot-summary-date {
  color: #6f6f6d;
  padding-top: 0.5em;
}

.ballot-detail {
  font-style: italic;
  line-height: 1.5em;
  margin-bottom: 1em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: none;
}

.step-style > .ballot-detail {
  margin-left: 2em;
}

.step-style {
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #f0f0f0;
}

.ballot-step-wrapper:last-child div {
  border-bottom: none;
}

.timeline-style {
  display: none;
}
@media screen and (min-width: 1200px) {
  .timeline-style {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 75px;
  }
}

.current-ballot-details-header {
  display: block;
  background-color: #f0f0f0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-size: 1.2em;
  color: #6c6a6a;
  -ms-grid-row: 2;
  -ms-grid-column-span: 3;
  overflow: hidden;
}
@media screen and (min-width: 1200px) {
  .current-ballot-details-header {
    grid-column: 1/span 2;
  }
}

.details-collapsable-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2em 0;
  user-select: none;
  transition: background-color 0.5s ease-out;
  overflow: hidden;
}
@media only screen and (min-width: 768px) {
  .details-collapsable-header {
    padding: 0.3em 0;
  }
}
.details-collapsable-header img {
  width: 30px !important;
  height: 30px;
  transition: transform 0.3s ease-out;
}

.ios-app-link {
  display: block;
  overflow: hidden;
  width: 135px;
  height: 40px;
  margin: 2em auto;
  background-size: contain;
  transform: scale(1.15);
}
@media only screen and (min-width: 992px) {
  .ios-app-link {
    bottom: 1.5em;
    position: absolute;
    left: 0;
    margin-left: 2em;
  }
}

.past-ballots-list {
  padding: 1em 0;
  background-color: white;
}
@media screen and (min-width: 768px) {
  .past-ballots-list {
    padding: 1em;
  }
}
.past-ballots-list .ballot-summary {
  margin-left: 2em;
}
@media screen and (min-width: 768px) {
  .past-ballots-list .ballot-summary {
    margin-left: 0;
  }
}

.past-ballot-trigger, .past-ballot-list-header {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.25fr 0.75fr 20px;
  grid-template-columns: 1.25fr 0.75fr 20px;
  align-items: center;
  text-align: left;
  padding: 0 1rem;
  cursor: pointer;
  user-select: none;
}
.past-ballot-trigger :nth-child(1), .past-ballot-list-header :nth-child(1) {
  -ms-grid-column: 1;
  -ms-grid-row-align: center;
  min-width: 0;
}
.past-ballot-trigger :nth-child(2), .past-ballot-list-header :nth-child(2) {
  -ms-grid-column: 2;
  -ms-grid-row-align: center;
}
.past-ballot-trigger :nth-child(3), .past-ballot-list-header :nth-child(3) {
  -ms-grid-column: 3;
  -ms-grid-row-align: center;
}
.past-ballot-trigger :nth-child(4), .past-ballot-list-header :nth-child(4) {
  -ms-grid-column: 4;
  -ms-grid-row-align: center;
}
@media screen and (min-width: 600px) {
  .past-ballot-trigger, .past-ballot-list-header {
    -ms-grid-columns: 1.25fr 1fr 1fr 20px;
    grid-template-columns: 1.25fr 1fr 1fr 20px;
    padding: 0 1rem;
  }
}

.past-ballot-list-header {
  display: none;
  text-transform: uppercase;
  margin-bottom: 1em;
  cursor: initial;
}
@media screen and (min-width: 425px) {
  .past-ballot-list-header {
    display: -ms-grid;
    display: grid;
  }
}

.past-ballot-trigger {
  background-color: #d6d6d6;
  color: #818181;
  font-size: 0.9em;
  margin-bottom: 0.15em;
  height: 2.5em;
  align-items: center;
}

.past-ballot-election-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1em;
}

.ballot-status,
.past-ballot-status {
  display: none;
}
@media screen and (min-width: 600px) {
  .ballot-status,
.past-ballot-status {
    display: -ms-grid;
    display: grid;
  }
}

.loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.ambiguous-container {
  flex: 1 1 auto;
}
@media screen and (min-width: 992px) {
  .ambiguous-container {
    margin: 3em 0;
  }
}

.ambiguous-title-section {
  margin: 3em 0 2.5em 0;
}
.ambiguous-title-section .header {
  color: #03314c;
  font-size: 1.2em;
  margin: 0;
}
@media screen and (min-width: 992px) {
  .ambiguous-title-section {
    margin: 0 0 2em 0;
  }
}

.ambiguous-login-form section {
  background-color: white;
}
.ambiguous-login-form section label {
  display: block;
  font-size: 1em;
  color: #03314c;
  text-transform: uppercase;
}
.ambiguous-login-form section input {
  margin-top: 1em;
  width: 100%;
  border: none;
  border-bottom: 1px solid grey;
}
.ambiguous-login-form section input::placeholder {
  color: #c0c0c0;
}

.ambiguous-buttons-container {
  display: flex;
  flex-flow: column;
  margin: 2em 0;
}
.ambiguous-buttons-container > button {
  border-radius: 3px;
  padding: 1em;
}
.ambiguous-buttons-container .primary-button {
  margin-bottom: 1em;
}
.ambiguous-buttons-container .secondary-button {
  background-color: #f0f0f0;
  color: black;
}
@media screen and (min-width: 425px) {
  .ambiguous-buttons-container {
    flex-flow: row;
  }
  .ambiguous-buttons-container .primary-button {
    margin-bottom: 0;
    flex: 3 0 auto;
    margin-right: 1em;
  }
  .ambiguous-buttons-container .secondary-button {
    flex: 1 1 auto;
  }
}
@media screen and (min-width: 500px) {
  .ambiguous-buttons-container {
    margin: 2em auto;
    width: 80%;
  }
}
@media screen and (min-width: 768px) {
  .ambiguous-buttons-container {
    width: 70%;
  }
}
@media screen and (min-width: 992px) {
  .ambiguous-buttons-container {
    flex-flow: column;
    width: 100%;
  }
  .ambiguous-buttons-container .primary-button {
    margin: 0 0 1em 0;
  }
}
@media screen and (min-width: 1200px) {
  .ambiguous-buttons-container {
    flex-flow: row;
  }
  .ambiguous-buttons-container .primary-button {
    flex: 3 0 auto;
    margin: 0 1em 0 0;
  }
  .ambiguous-buttons-container .secondary-button {
    flex: 1 1 auto;
  }
}

.token-container {
  flex: 1 1 auto;
}
@media screen and (min-width: 992px) {
  .token-container {
    margin: 3em 0;
  }
}

.token-title-section {
  margin: 3em 0 2.5em 0;
}
.token-title-section .header {
  color: #03314c;
  font-size: 1.2em;
  margin: 0;
}
@media screen and (min-width: 992px) {
  .token-title-section {
    margin: 0 0 2em 0;
  }
}

.token-login-form section {
  background-color: white;
}
.token-login-form section label {
  display: block;
  font-size: 1em;
  color: #03314c;
  text-transform: uppercase;
}
.token-login-form section input {
  margin-top: 1em;
  width: 100%;
  border: none;
  border-bottom: 1px solid grey;
}
.token-login-form section input::placeholder {
  color: #c0c0c0;
}

.token-buttons-container {
  display: flex;
  flex-flow: column;
  margin-top: 3em;
}
.token-buttons-container > button {
  border-radius: 3px;
  padding: 1em;
}
.token-buttons-container .primary-button {
  margin-bottom: 1em;
}
@media screen and (min-width: 425px) {
  .token-buttons-container {
    flex-flow: row;
  }
  .token-buttons-container .primary-button {
    margin-bottom: 0;
    flex: 3 0 auto;
    margin-right: 1em;
  }
  .token-buttons-container .secondary-button {
    flex: 1 1 auto;
  }
}
@media screen and (min-width: 500px) {
  .token-buttons-container {
    margin: 3em auto 0 auto;
    width: 80%;
  }
}
@media screen and (min-width: 768px) {
  .token-buttons-container {
    width: 70%;
  }
}
@media screen and (min-width: 992px) {
  .token-buttons-container {
    flex-flow: column;
    width: 100%;
  }
  .token-buttons-container .primary-button {
    margin: 0 0 1em 0;
  }
}
@media screen and (min-width: 1200px) {
  .token-buttons-container {
    flex-flow: row;
  }
  .token-buttons-container .primary-button {
    flex: 3 0 auto;
    margin: 0 1em 0 0;
  }
  .token-buttons-container .secondary-button {
    flex: 1 1 auto;
  }
}

.policy-page {
  display: flex;
  flex-flow: column;
  height: 100vh;
  overflow: auto;
}
.policy-page main {
  padding: 1em 1em 0 1em;
  margin-bottom: 3em;
}
.policy-page main h1 {
  font-size: 1.5em;
  padding: 0;
  margin: 1rem 0;
  text-transform: uppercase;
}
@media only screen and (min-width: 768px) {
  .policy-page main {
    padding: 2em 0 0 0;
  }
  .policy-page main h1 {
    margin: 0 0 1.5rem 0;
  }
}
.policy-page section {
  align-self: center;
  justify-self: flex-start;
  margin-bottom: 3em;
}
.policy-page section button {
  cursor: pointer;
}
.policy-page footer {
  margin: auto 0 1.5em 0;
  padding: 0;
  align-self: center;
}

.faq-page {
  height: unset;
}
.faq-page main .faq-error {
  display: flex;
  justify-content: center;
}
.faq-page main p span {
  font-weight: bold;
}
.faq-page main dl.faq-page-dl {
  padding: 0;
}
.faq-page main dl.faq-page-dl dt,
.faq-page main .question {
  font-weight: bold;
}
.faq-page main dl.faq-page-dl dd,
.faq-page main .answer {
  margin-bottom: 1.5em;
  margin-inline-start: 0;
  margin-top: 1em;
  color: #75797e;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em;
  margin: 1em;
  padding-top: 1.4em;
}
.dashboard-grid .dashboard-ballot-status-tab {
  margin-top: 2em;
  width: unset;
}
@media screen and (min-width: 900px) {
  .dashboard-grid {
    grid-template-columns: 2fr 1fr;
    margin: 0 2em;
  }
  .dashboard-grid .dashboard-grid-help {
    margin-top: 6.1em;
  }
  .dashboard-grid .dashboard-grid-help-tabbed {
    margin-top: 2em;
  }
  .dashboard-grid .dashboard-grid-footer {
    grid-column: span 2;
  }
}
@media screen and (min-width: 1200px) {
  .dashboard-grid {
    margin: 0 8%;
  }
}
@media only screen and (min-width: 1800px) {
  .dashboard-grid {
    margin: 0 15%;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .dashboard-grid {
    display: -ms-grid;
    -ms-grid-columns: 2fr 1fr;
  }
  .dashboard-grid .grid-item {
    margin: 0.7em;
  }
  .dashboard-grid .dashboard-grid-footer {
    -ms-grid-column-span: 2;
    -ms-grid-row: 2;
  }
  .dashboard-grid .dashboard-grid-prefs {
    -ms-grid-column: 2;
  }
}
.dashboard-title {
  color: #03314c;
  font-size: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 2em;
  padding-bottom: 1em;
}
@media screen and (min-width: 425px) {
  .dashboard-title {
    font-size: 1.5em;
  }
}

.dashboard-card {
  box-shadow: 1px 3px 5px 1px #ccc;
  border-radius: 6px;
  background-color: white;
  color: #6f6f6d;
  min-height: 18em;
}

.dashboard-card-header {
  background-color: #03314c;
  color: white;
  height: 3.5em;
  display: flex;
  align-items: center;
  padding: 1em;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 5px solid #0185bb;
}
@media screen and (max-width: 375px) {
  .dashboard-card-header {
    font-size: 0.8em;
    letter-spacing: 1px;
  }
}

.home-page-layout {
  display: flex;
  flex-flow: column;
  background-color: white;
}
@media only screen and (min-width: 992px) {
  .home-page-layout {
    flex-direction: row;
    min-height: calc(100vh - 4em);
  }
}

.login-container {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 1em 1em 1.5em 1em;
  width: 100%;
  color: rgba(26, 39, 85, 0.5);
  background-color: white;
}
@media only screen and (min-width: 768px) {
  .login-container {
    padding: 1em 4em 1em 4em;
  }
}
@media only screen and (min-width: 992px) {
  .login-container {
    display: flex;
    justify-content: normal;
    padding: 1em 1.5em 0 1.5em;
    width: 35%;
    height: calc(100vh - 4em);
    overflow-y: auto;
  }
}
@media only screen and (min-width: 1200px) {
  .login-container {
    width: 30%;
  }
}
@media only screen and (min-width: 1800px) {
  .login-container {
    width: 25%;
  }
}

.no-ballots {
  display: flex;
  flex-direction: column;
}

.detail {
  font-style: italic;
  text-align: center;
}

.no-ballot-content {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}
.no-ballot-content .status-sub-title {
  color: #03314c;
  font-size: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 0;
  padding-bottom: 1em;
}
@media screen and (min-width: 425px) {
  .no-ballot-content .status-sub-title {
    font-size: 1.5em;
  }
}
.no-ballot-content .detail {
  padding: 1vh 4vw 0 4vw;
}
.no-ballot-content hr {
  width: 70%;
}

.preferences-page {
  max-width: 38em;
  margin: 0 auto;
}

.card {
  width: 90%;
  box-shadow: 1.1px 1.5px 2.1px #ccc;
  border-radius: 6px;
  font-size: 0.9em;
  border-left: 0.5px solid rgba(0, 0, 0, 0.1);
  place-self: center;
  min-width: 90%;
}
.card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #03314c;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 3em;
  color: white;
  padding: 1.25em;
}
.card .card-header-title {
  text-transform: uppercase;
  font-weight: bold;
}
.card .card-header-subtitle {
  text-transform: uppercase;
  font-weight: normal;
  color: #b4cdea;
}
.card .card-detail {
  padding: 1.5em 1.25em;
  height: 90%;
}

.elected-officials-tab {
  margin-top: 1em;
  width: unset;
}

.head-container {
  display: flex;
  gap: 0.1em;
}
@media only screen and (max-width: 425px) {
  .head-container #mobile-header {
    z-index: 2;
    display: block;
    width: 100%;
    overflow-x: visible;
    height: 2em;
  }
  .head-container #full-header {
    display: none;
  }
}
@media only screen and (min-width: 425px) {
  .head-container {
    display: flex;
    gap: 0.1em;
  }
  .head-container #mobile-header {
    display: none;
  }
  .head-container #full-header {
    display: flex;
    gap: 0.1em;
    flex: 1;
    cursor: pointer;
  }
  .head-container .tab-card-header {
    flex: 1;
  }
}
.head-container .red-head {
  background-color: red;
}
.head-container .inactive {
  background-color: #b4b4b4;
}
.head-container .card-header-title {
  text-transform: unset;
}

.body-container {
  background: transparent;
  overflow: scroll;
}
@media only screen and (min-width: 425px) {
  .body-container {
    max-height: 80vh;
  }
}

.mobile-dropdown-item {
  cursor: pointer;
  color: black;
  background-color: #f0f0f0;
  border-color: black;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  padding: 0.5em 0.5em 0.5em 1em;
  font-size: 0.9em;
}
.mobile-dropdown-item:last-of-type {
  border-radius: 0 0 4px 4px;
}

.mobile-tab-card-header {
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #03314c;
  height: 2em;
  color: white;
  padding: 0.75em;
  border-radius: 6px 6px 0 0;
}

.my-elected-officials {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  background-color: #fff;
}
.my-elected-officials a {
  text-decoration: none;
  color: #245b75;
}
.my-elected-officials a:visited {
  color: #245b75;
}
.my-elected-officials .official-content {
  display: flex;
  flex-direction: column;
}
.my-elected-officials .official-content hr {
  margin-top: 0;
  margin-bottom: 0;
  width: 95%;
}
.my-elected-officials .official-content hr:last-child {
  display: none;
}
.my-elected-officials .back-to-elected-representative {
  position: absolute;
  transform: rotate(180deg);
  cursor: pointer;
}
.my-elected-officials .elected-representative {
  padding: 0.5em;
  background-color: #fff;
  display: flex;
  color: #6f6f6d;
  cursor: pointer;
  flex-direction: row;
}
.my-elected-officials .elected-representative .photo-details-container {
  display: flex;
  flex-grow: 1;
}
@media only screen and (max-width: 425px) {
  .my-elected-officials .elected-representative .photo-details-container {
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 425px) {
  .my-elected-officials .elected-representative .photo-details-container {
    flex-direction: row;
  }
}
.my-elected-officials .elected-representative .photo-details-container .photo-container {
  flex: none;
  width: 10em;
}
.my-elected-officials .elected-representative .photo-details-container .photo-container .photo {
  width: 66%;
  border-radius: 0.8em;
  margin-left: 1em;
}
.my-elected-officials .elected-representative .photo-details-container .details-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  margin: 0 1.3em;
}
.my-elected-officials .elected-representative .photo-details-container .details-container .name {
  font-size: 1.5em;
  font-weight: bold;
}
.my-elected-officials .elected-representative .photo-details-container .details-container .title {
  font-size: 1.25em;
}
@media only screen and (min-width: 425px) {
  .my-elected-officials .elected-representative .photo-details-container .details-container {
    justify-content: center;
  }
}
@media only screen and (max-width: 425px) {
  .my-elected-officials .elected-representative .photo-details-container .details-container {
    align-items: center;
    margin-left: 3em;
  }
  .my-elected-officials .elected-representative .photo-details-container .details-container .name {
    font-size: 1.5em;
  }
  .my-elected-officials .elected-representative .photo-details-container .details-container .title {
    font-size: 1em;
  }
}
.my-elected-officials .elected-representative .more-details-arrow-container {
  width: 2em;
  align-self: center;
}
.my-elected-officials .elected-representative-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5em 1em;
}
.my-elected-officials .elected-representative-details a {
  text-decoration: none;
  color: #245b75;
}
.my-elected-officials .elected-representative-details a:visited {
  color: #245b75;
}
.my-elected-officials .elected-representative-details .photo {
  height: 16em;
  border-radius: 1em;
}
.my-elected-officials .elected-representative-details .details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: larger;
  text-align: center;
  margin: 2em 1em;
}
.my-elected-officials .elected-representative-details .details-container .urls {
  font-size: 2em;
}
.my-elected-officials .elected-representative-details .details-container .title {
  font-size: 1.5em;
}
.my-elected-officials .elected-representative-details .details-container .party {
  font-size: 1.5em;
  font-style: italic;
}
.my-elected-officials .elected-representative-details .contact-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
@media only screen and (min-width: 425px) {
  .my-elected-officials .elected-representative-details .contact-info-container {
    flex-direction: row;
  }
}
.my-elected-officials .elected-representative-details .contact-info-container .contact-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.8em 1em 0.8em;
}
.my-elected-officials .elected-representative-details .contact-info-container .contact-item-container .contact-item {
  width: 3em;
  height: 3em;
  background-color: #0185bb;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.my-elected-officials .elected-representative-details .contact-info-container .contact-item-container .contact-label {
  font-size: small;
  margin-top: 0.5em;
}
.my-elected-officials .elected-representative-details .contact-info-container .contact-item-container.inactive {
  opacity: 0.1;
}
.my-elected-officials .elected-representative-details .contact-info-container .website,
.my-elected-officials .elected-representative-details .contact-info-container .email,
.my-elected-officials .elected-representative-details .contact-info-container .phones,
.my-elected-officials .elected-representative-details .contact-info-container .maps {
  font-size: 0.6em;
  padding-bottom: 0.2em;
  padding-top: 0.2em;
  display: flex;
  align-self: center;
}
.my-elected-officials .elected-representative-details .contact-info-container .website a {
  display: flex;
  align-items: center;
}
.my-elected-officials .elected-representative-details .social-container {
  align-items: center;
  display: flex;
  flex-direction: row;
}
.my-elected-officials .elected-representative-details .social-container a {
  margin: 1em;
}

.election-officials-loader {
  align-items: flex-start;
  margin-top: 2em;
}

.polling-locations {
  display: flex;
  background-color: white;
  flex-direction: column;
}
@media only screen and (min-width: 425px) {
  .polling-locations {
    flex-direction: row;
  }
}
.polling-locations hr.content-divider:last-child {
  display: none;
}
.polling-locations .dashboard-card {
  box-shadow: unset;
}
.polling-locations .search-container {
  flex: 1 0;
}
.polling-locations .search {
  flex: 1 0;
  color: #6f6f6d;
  padding: 1em;
}
.polling-locations .search .title {
  margin-bottom: 0.5em;
}
.polling-locations .search .search-input {
  margin-bottom: 1em;
  position: relative;
  width: 100%;
}
.polling-locations .search .search-input .icon {
  float: left;
  position: absolute;
  z-index: 1;
}
.polling-locations .search .search-input input {
  padding-top: 2px;
  padding-left: 14px;
  margin-top: -2px;
  width: 100%;
  border-style: none;
  border-bottom: 1px solid #6f6f6d;
  background-color: transparent;
}
.polling-locations .search .search-result {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.polling-locations .search .search-result .polling-type {
  margin-top: 0.5em;
}
.polling-locations .search .search-result .polling-type .polling-location-type-header {
  margin-bottom: 0.8em;
  display: flex;
  gap: 0.5em;
  align-items: center;
  font-size: 1.2em;
}
.polling-locations .search .search-result .polling-type .polling-locations-container {
  min-height: unset;
  padding: 0.5em;
  margin-bottom: 0.2em;
}
.polling-locations .search .search-result .polling-type .polling-locations-container .polling-location {
  display: flex;
}
.polling-locations .search .search-result .polling-type .polling-locations-container .polling-location .polling-label {
  width: 2em;
  margin-left: 0;
  position: relative;
}
.polling-locations .search .search-result .polling-type .polling-locations-container .polling-location .polling-label .label {
  position: absolute;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 0.8em;
}
.polling-locations .search .search-result .polling-type .polling-locations-container .polling-location .polling-label .marker {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.polling-locations .search .search-result .polling-type .polling-locations-container .polling-location .polling-address .address-line {
  text-transform: capitalize;
}
.polling-locations .search .search-result .polling-type .polling-locations-container .polling-location .polling-address .address-line.location-name {
  font-weight: bold;
}
.polling-locations .search .search-result .polling-type .polling-locations-container .polling-location .polling-address .directions-link {
  margin: 1em 0 1em 0;
  display: block;
  text-decoration: none;
}
.polling-locations .search .search-result .polling-type .polling-locations-container .polling-location .polling-address .directions-link:visited {
  color: blue;
}
.polling-locations .map {
  flex: 1 0;
  overflow-x: hidden;
}

.modal-title {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding-right: 1em;
  padding-left: 1em;
  background-color: #002840;
  color: white;
  text-transform: uppercase;
}

.close-modal {
  color: rgba(255, 255, 255, 0.6);
  size: 2em;
  cursor: pointer;
  border: none;
  background-color: #002840;
}

.ReactModal__Body--open {
  height: 10em;
}

dl.faq-dl {
  padding: 1em 2em;
}

dl.faq-dl dt,
.question {
  font-weight: bold;
}

dl.faq-dl dd,
.answer {
  padding-bottom: 1em;
  color: #75797e;
  margin-inline-start: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}

.faq-modal-error {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: white;
  outline: none;
}
@media screen and (min-width: 768px) {
  .modal {
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 10%;
  }
}
@media screen and (min-width: 1200px) {
  .modal {
    top: 15%;
    left: 15%;
    right: 15%;
    bottom: 15%;
  }
}
@media screen and (max-height: 500px) {
  .modal {
    top: 0;
    bottom: 0;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.content {
  height: 90%;
  overflow: auto;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 1em;
}

.modal-link-button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: #6f6f6d;
}

.unsubscribe-page {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.unsubscribe-content {
  line-height: 1.4em;
}

.unsubscribe-page .buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.unsubscribe-page .buttons-container .unsubscribe-button,
.unsubscribe-page .buttons-container .visit-button {
  width: 14em;
  font-size: 13px;
  padding: 11px;
  margin: 1em 0.5em 0.5em 0.5em;
}

h1.h1-unsubscribe-error {
  font-size: 1.5em;
}

.unsubscribe-error {
  display: flex;
}
.unsubscribe-error .error-icon {
  margin-right: 0.3em;
}

.confirmation-login-page {
  height: 100%;
}

.confirmation-language-dropdown-container {
  display: flex;
  justify-content: flex-end;
  background-color: #03314c;
  height: 4em;
  width: 100%;
  padding: 0.5em;
}
@media only screen and (min-width: 500px) {
  .confirmation-language-dropdown-container {
    margin-bottom: 0;
  }
}

.outer-confirmation-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0c3a58;
  height: 100%;
}
.outer-confirmation-login-container .confirmation-blue-background {
  background-color: #f0f0f0;
  max-width: none;
  height: 100%;
  border: solid 4px #f0f0f0;
}
.outer-confirmation-login-container .confirmation-login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}
.outer-confirmation-login-container .confirmation-logo-container {
  display: flex;
  justify-content: center;
  background-color: #0c3a58;
  width: 100%;
  padding: 0.5em 1em;
  margin-bottom: 0.5em;
}
.outer-confirmation-login-container .account-logo {
  width: auto;
  max-width: 280px;
  max-height: 300px;
}
.outer-confirmation-login-container .confirmation-login-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0.5em 2em;
  background-color: #f0f0f0;
}
.outer-confirmation-login-container .confirmation-text {
  color: #767676;
  font-size: 13px;
  font-style: italic;
  text-align: left;
  width: 100%;
}
.outer-confirmation-login-container .confirmation-input {
  width: 100%;
}
.outer-confirmation-login-container .confirmation-error-text {
  color: #d45659;
}
.outer-confirmation-login-container .confirmation-example {
  color: #767676;
  font-size: 14px;
  margin-top: 1em;
  text-align: left;
}
.outer-confirmation-login-container .confirmation-label {
  color: #0b314d;
  font-size: 16px;
  margin-top: 1em;
  margin-bottom: 1em;
}
.outer-confirmation-login-container .login-button {
  margin-top: 1em;
  padding: 1em;
  width: 100%;
}

@media only screen and (min-width: 500px) {
  .outer-confirmation-login-container {
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 12% 12%;
    background-color: #f0f0f0;
  }
  .outer-confirmation-login-container .confirmation-blue-background {
    background-color: #0c3a58;
    max-width: 450px;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  .outer-confirmation-login-container .confirmation-logo-container {
    margin-top: 0.5em;
  }
  .outer-confirmation-login-container .confirmation-login-container {
    margin: 0 1em 1em 1em;
    padding: 1em;
  }
}
.confirmation_resend_link {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  text-transform: lowercase;
  size: 1em;
  margin-top: 2em;
  width: 100%;
  text-align: right;
}

.confirm-email-loader {
  height: 100%;
  align-items: center;
}

.confirm-email-voter-name {
  color: white;
}

.confirm-email-language-dropdown {
  align-items: center;
}

.delivery-confirmation-status-confirmed {
  display: flex;
  justify-content: right;
  color: #2b9d2b;
  font-style: italic;
  font-size: 0.75em;
  margin-top: -1.5em;
}

.delivery-confirmation-status-unconfirmed {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fdb9036c;
  padding: 10px;
  margin: 0 0 1em 0;
  font-size: 1em;
  border: 1px solid black;
}
.delivery-confirmation-status-unconfirmed .opt-in {
  display: flex;
  align-items: center;
}
.delivery-confirmation-status-unconfirmed .opt-in img {
  margin-right: 10px;
}
.delivery-confirmation-status-unconfirmed .confirmation-status {
  color: black;
  font-weight: bold;
}
.delivery-confirmation-status-unconfirmed .confirmation-status.confirmed {
  color: #2b9d2b;
}
.delivery-confirmation-status-unconfirmed .verification-link,
.delivery-confirmation-status-unconfirmed .enter-confirmation-code-link {
  color: #2525fd;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 1em;
}
.delivery-confirmation-status-unconfirmed .enter-confirmation-code-button {
  width: 13em;
  margin-top: 0.25em;
}

.confirmation-code-inline {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  margin-bottom: 0.25em;
}
.confirmation-code-inline img {
  margin-right: 10px;
}
.confirmation-code-inline button {
  padding: 0.25em;
  width: unset;
  margin-left: 0.25em;
}

.email-resend-countdown {
  color: black;
  font-size: 0.8em;
}

.sms-policy-list {
  padding-right: 2.5em;
}

.sms-policy-li {
  margin-bottom: 1em;
  font-size: 0.9em;
}

.sms-link-button {
  color: blue;
  cursor: pointer;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  font-style: italic;
  font-size: 0.9em;
  font-weight: 300;
}

.pagination {
  margin-top: 0.9em;
}
.pagination .pagination-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  color: #000000;
}
.pagination .button-container {
  display: flex;
  width: fit-content;
}
.pagination .disabled {
  color: #75797e;
}
.pagination .page-count {
  font-weight: bold;
  min-width: fit-content;
}
.pagination button {
  border: none;
  text-transform: uppercase;
  line-height: 25px;
  background-color: transparent;
  color: #000000;
  cursor: pointer;
}
.pagination button .previous-arrow {
  margin-right: -0.4em;
}
.pagination button .next-arrow {
  margin-left: -0.4em;
  vertical-align: bottom;
}
.pagination button .arrow-left,
.pagination button .arrow-right {
  vertical-align: bottom;
}
.pagination .prev-button,
.pagination .next-button {
  width: max-content;
}
.pagination button.disabled {
  cursor: not-allowed;
}

.release-notes-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 92%;
  margin-right: 1em;
  margin-left: 1em;
}
.release-notes-container .description {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}
.release-notes-container .pagination-description {
  font-size: 0.8em;
  font-style: italic;
  margin-top: 0.5em;
}
.release-notes-container .release-notes-list {
  overflow: auto;
  padding-left: 1em;
  padding-right: 1em;
  height: 82%;
  margin-bottom: 0;
}
.release-notes-container .release-notes-li {
  display: flex;
  margin-bottom: 1em;
  line-height: 1.5em;
  list-style: none;
}
.release-notes-container .published {
  margin-right: 1em;
  width: 6em;
}
.release-notes-container .note {
  white-space: pre-wrap;
}
.release-notes-container .release-notes-pagination {
  margin-top: 0;
}

.release-notes-loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme-orig {
  background-color: #f0f0f0;
  font-family: "Lato", sans-serif;
}
.theme-orig .theme3000 {
  display: none;
}

.theme-2018 {
  background-color: white;
  font-family: "Lato", sans-serif;
}
.theme-2018 .nav-bar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 10;
}
.theme-2018.past-ballots-list {
  display: flex;
  flex-flow: column;
  padding: 0;
  background-color: white;
  min-height: 0;
}
.theme-2018.past-ballots-list .past-ballot:last-child {
  border-bottom: none;
}
.theme-2018.past-ballots-list .past-ballot {
  display: flex;
  flex-flow: column;
  padding: 1em;
  border-bottom: 1px solid rgba(3, 49, 76, 0.1);
}
.theme-2018.past-ballots-list .past-ballot .details > p {
  padding: 0;
  margin: 0;
  border: none;
  font-size: 1em;
}
.theme-2018.past-ballots-list .past-ballot .details .name {
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  color: #03314c;
}
.theme-2018.past-ballots-list .past-ballot .details .date {
  margin-top: 0.25em;
}
.theme-2018.past-ballots-list .past-ballot .status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0 0 0;
  min-width: 220px;
}
.theme-2018.past-ballots-list .past-ballot .status.accepted {
  color: #2e852e;
}
.theme-2018.past-ballots-list .past-ballot .status.rejected {
  color: #e6210f;
}
.theme-2018.past-ballots-list .past-ballot .status.incomplete {
  color: #6f6f6d;
}
.theme-2018.past-ballots-list .past-ballot .status > p {
  margin: 0;
}
@media screen and (min-width: 500px) {
  .theme-2018.past-ballots-list .past-ballot {
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }
  .theme-2018.past-ballots-list .past-ballot .status {
    justify-content: space-between;
    margin-left: 1em;
    padding: 0;
  }
  .theme-2018.past-ballots-list .past-ballot .details {
    width: 50%;
  }
}
.theme-2018 .past-ballot-accepted {
  fill: #2e852e;
}
.theme-2018 .past-ballot-rejected {
  fill: #e6210f;
}
.theme-2018 .past-ballot-incomplete {
  fill: grey;
  opacity: 0.3;
}
.theme-2018 .themeOrig {
  display: none;
}

html,
body,
#root,
#root > div,
#app {
  height: 100%;
}

#root {
  display: block;
}

body {
  background-color: #f0f0f0;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.screen-reader {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.skip-link {
  position: absolute;
  margin-top: 5em;
  padding: 0.6em;
  border-radius: 3px;
  font-family: "Open Sans", sans-serif;
  font-size: 0.8em;
  color: transparent;
  background-color: transparent;
  transition: ease-in-out, all 0.25s ease-in-out;
}

.skip-link:focus {
  color: #03314c;
  background-color: #f0f0f0;
}

.skip-link-logged-in {
  display: none;
}
@media only screen and (min-width: 768px) {
  .skip-link-logged-in {
    display: block;
  }
}

@-moz-document url-prefix() {
  *:focus,
button:focus,
button[type=submit]:focus {
    outline: 2px #4d90fe auto;
    outline-offset: -2px;
  }
}

/*# sourceMappingURL=index.css.map */
