@use '../partials/base';

.login-information {
  display: flex;
  flex-direction: column;
  font-size: 0.9em;

  .get-started {
    color: base.$primary-color;
    font-size: 1.6em;
    text-transform: uppercase;
    font-weight: bold;
    font-style: initial;
    margin-bottom: 1.5rem;
  }

  .registration-notice,
  .branding-blurb {
    font-style: italic;
  }

  .registration-notice {
    color: base.$info-color;
    padding: 0.25em 0 0.25em 1em;
    p {
      margin: 0;
    }

    .faq-link,
    .wmb-info {
      margin-top: 1em;
    }
    border-left: rgba(26, 39, 85, 0.8) 3px solid;
  }

  @media only screen and (min-width: base.$desktop) {
    margin: 2em 0 0 0;
    flex: 1 0 auto;

    .get-started {
      text-align: center;
    }
  }

  @media (max-width: base.$phone) {
    font-size: 0.8em;
    margin-bottom: 0.5em;
  }
}
