//# set theme layout here, i.e. grid structure
@use '../partials/base';

%page-layout {
  margin: 0;

  @media only screen and (min-width: base.$tablet) {
    margin: 0 10%;
  }

  @media only screen and (min-width: base.$desktop) {
    margin: 0 11%;
  }

  @media only screen and (min-width: base.$xlarge) {
    margin: 0 8%;
  }

  @media only screen and (min-width: base.$high-res) {
    margin: 0 25%;
  }
}

.page-layout {
  @extend %page-layout;
}

// Fix to SVGs looking "squished" on IE11
// Source: https://gist.github.com/larrybotha/7881691#target-ie-with-css
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$='.svg'] {
    width: 100%;
  }
}
