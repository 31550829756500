//# create buttons here
@use '../partials/base';

%button {
  padding: 1.25em 1em;
  border: none;
  letter-spacing: 2px;
  font-size: 1em;
  text-transform: uppercase;
  cursor: pointer;
}

.primary-button {
  @extend %button;
  background-color: base.$primary-color;
  color: base.$secondary-color;
}

.secondary-button {
  @extend %button;
  background-color: base.$secondary-color;
  color: black;
}

.preferences-submit-button {
  width: 20%;
}

.reset-preferences-button {
  width: 20%;
}
