@use '../partials/base';

.policy-page {
  display: flex;
  flex-flow: column;
  height: 100vh;
  overflow: auto;

  main {
    padding: 1em 1em 0 1em;
    margin-bottom: 3em;

    h1 {
      font-size: 1.5em;
      padding: 0;
      margin: 1rem 0;
      text-transform: uppercase;
    }

    @media only screen and (min-width: base.$tablet) {
      padding: 2em 0 0 0;

      h1 {
        margin: 0 0 1.5rem 0;
      }
    }
  }

  section {
    align-self: center;
    justify-self: flex-start;
    margin-bottom: 3em;

    button {
      cursor: pointer;
    }
  }

  footer {
    margin: auto 0 1.5em 0;
    padding: 0;
    align-self: center;
  }
}
