@use '../partials/base';

.elected-officials-tab {
  margin-top: 1em;
  width: unset;
}

.head-container {
  display: flex;
  gap: 0.1em;

  @media only screen and (max-width: base.$phone) {
    #mobile-header {
      z-index: 2;
      display: block;
      width: 100%;
      overflow-x: visible;
      height: 2em;
    }
    #full-header {
      display: none;
    }
  }

  @media only screen and (min-width: base.$phone) {
    display: flex;
    gap: 0.1em;

    #mobile-header {
      display: none;
    }

    #full-header {
      display: flex;
      gap: 0.1em;
      flex: 1;
      cursor: pointer;
    }

    .tab-card-header {
      flex: 1;
    }
  }

  .red-head {
    background-color: red;
  }
  .inactive {
    background-color: #b4b4b4;
  }
  .card-header-title {
    text-transform: unset;
  }
}

.body-container {
  background: transparent;
  overflow: scroll;

  @media only screen and (min-width: base.$phone) {
    max-height: 80vh;
  }
}

.mobile-dropdown-item {
  cursor: pointer;
  color: black;
  background-color: #f0f0f0;
  border-color: black;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  padding: 0.5em 0.5em 0.5em 1em;
  font-size: 0.9em;

  &:last-of-type {
    border-radius: 0 0 4px 4px;
  }
}

.mobile-tab-card-header {
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(3, 49, 76);
  height: 2em;
  color: white;
  padding: 0.75em;
  border-radius: 6px 6px 0 0;
}
