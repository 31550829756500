@use '../partials/base';

.dashboard-collapsable {
  margin-top: 2.5em;
  width: 100%;

  @media only screen and (min-width: base.$tablet) {
    margin-top: 3.5em;
  }
}

.collapsable-header {
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  color: #03314c;
  width: 100%;
  padding: 0.5em 0;
  font-size: 1.25em;
  cursor: pointer;
  user-select: none;
  background-color: white;
  transition: background-color 0.5s ease-out;

  @media only screen and (min-width: base.$tablet) {
    font-size: 1.6em;
    padding: 0.75em 0;
  }

  img {
    // !important is a fix for ie11 centering of text, it defaults img with src svgs to 100% width it seems
    width: 35px !important;
    height: 35px;
    transition: transform 0.3s ease-out;
  }
}

.header-opened {
  background-color: rgba(255, 255, 255, 0);

  img {
    transform: rotate(-180deg);
  }
}

.header-closed {
  background-color: rgba(255, 255, 255, 1);

  img {
    transform: rotate(0deg);
  }
}

.header-text {
  justify-self: stretch;
  margin-right: 0.5em;
}
