@use '../partials/base';

.no-ballots {
  display: flex;
  flex-direction: column;
}

.detail {
  font-style: italic;
  text-align: center;
}

.no-ballot-content {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;

  .status-sub-title {
    color: base.$primary-color;
    font-size: 1.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 0;
    padding-bottom: 1em;

    @media screen and (min-width: base.$phone) {
      font-size: 1.5em;
    }
  }

  .detail {
    padding: 1vh 4vw 0 4vw;
  }

  hr {
    width: 70%;
  }
}
