//# create common modules here
@use '../partials/base';

%message-style {
  padding-top: 1em;
  font-style: italic;
  font-size: 0.9em;
  font-weight: 300;
}

.field-message {
  @media (max-width: base.$phone) {
    margin-bottom: 1em;
  }
  color: base.$info-color;
  @extend %message-style;
}

.error {
  color: base.$alert-color;
  display: block;
  @extend %message-style;
}
