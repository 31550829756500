@use '../partials/base';

.voter-profile {
  width: 100%;
  position: absolute;
  z-index: 10;
  padding: 1.5em;
  background-color: white;
  box-shadow: 1px 0 6px #d1d1d1;
  display: block;

  &.mobile {
    display: none;
  }

  @media only screen and (max-width: base.$xxsmall) {
    display: none;

    &.mobile {
      padding: 0;
      display: block;

      button {
        width: 100%;

        border-bottom: {
          style: solid;
          width: 1px;
        }
      }
    }
  }

  @media only screen and (min-width: base.$tablet) {
    padding: 3em;
  }

  @media only screen and (min-width: base.$xlarge) {
    padding: 3em 15%;
  }

  header {
    line-height: 1.2;
    font-size: 1.75em;
    color: #002840;
    padding-bottom: 1em;
    margin: 0;

    @media only screen and (min-width: base.$phone) {
      padding-bottom: 0.5em;
    }
  }

  button {
    display: block;
    margin: 0 auto;
    width: 14em;
    height: 3.1em;
    background-color: #002840;
    border: none;
    color: #ffffff;
    text-transform: uppercase;
  }
}

.voter-profile-content {
  width: 100%;
  display: block;
  margin-bottom: 4em;

  @media only screen and (min-width: base.$tablet) {
    display: flex;
    justify-content: space-between;
  }
}

.voter-profile-county-logo {
  display: none;
  max-width: 18em;

  @media only screen and (min-width: base.$tablet) {
    display: inline-block;
    width: 30%;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.voter-profile-ballottrax-description {
  font-size: 0.9em;
  line-height: 1.37;
  letter-spacing: 0.06em;
  margin: 0;
  padding-bottom: 2.5em;
  color: #989695;

  @media only screen and (min-width: base.$tablet) {
    padding-bottom: 3.5em;
  }
}

.voter-profile-information {
  letter-spacing: 0.05em;
  margin-bottom: 3em;
  font-size: 1em;

  @media only screen and (min-width: base.$tablet) {
    max-width: 30%;
    margin-bottom: 0;
  }

  p {
    padding: 0;
    margin: 0;
    color: #818181;
    font-size: 0.9em;
  }

  .sub-header {
    padding: 0 0 1em 0;
    margin: 0;
    color: #4a4c4f;
    font-size: 1em;
    text-transform: uppercase;
  }
}
