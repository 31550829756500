@use '../partials/base';

.global-alerts {
  height: 0;
  overflow: visible;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;
}

.alert-wrapper {
  outline: none;
}

.global-alert {
  display: flex;
  justify-content: space-between;

  color: white;
  user-select: none;
  cursor: default;

  a {
    color: white;
  }
}

.close-global-alert-btn {
  color: rgba(0, 0, 0, 0.3);
  font-size: 1.2em;
  background-color: rgba(255, 255, 255, 0.3);
  border: 0;
  padding: 0;
  margin-left: 1em;
  min-width: 2em;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
    color: rgba(0, 0, 0, 0.5);
  }

  @media only screen and (min-width: base.$tablet) {
    min-width: 2.5em;
    margin-left: 1.5em;
  }

  @media only screen and (min-width: base.$xlarge) {
    min-width: 3em;
  }
}

.global-alert-content {
  display: flex;
  flex-flow: column;
  padding: 0.75em 0 0.75em 1em;
  width: 100%;
  user-select: text;

  @media only screen and (min-width: base.$desktop) {
    padding: 1em 0 1em 3em;
  }
}

%base-global-alert {
  width: 100%;
  margin-bottom: 1em;

  &:first-child {
    text-align: left;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.global-alert-message {
  @extend %base-global-alert;
}

.global-alert-label {
  @extend %base-global-alert;
  margin-bottom: 1rem;
}

.error-alert {
  background-color: #f13827;
}

.notice-alert {
  background-color: #fdb903;
}

.success-alert {
  background-color: #6acc6a;
}

.contact-preferences-alert {
  min-width: 280px;
  background-color: #aa1209;
  padding: 0.75em 1em 0.75em 1em;
  display: flex;

  color: white;
  user-select: none;
  cursor: default;

  .contact-preferences-alert-icon {
    margin-right: 12px;
  }

  a {
    color: white;
  }

  @media only screen and (min-width: base.$tablet) {
    display: none;
  }
}
