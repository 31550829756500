@use '../partials/base';

.hero {
  display: flex;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 12% 12%;
  background-color: white;

  @media only screen and (min-width: base.$desktop) {
    flex-basis: 0;
    flex-grow: 1;
  }
}

.hero-content {
  display: flex;
  flex-flow: column;
  align-items: center;
  align-self: center;
  padding: 1.5em 1em;
  background-color: rgba(0, 48, 80, 0.95);
  border: solid 4px #ffffff;
  color: #fff;
  text-align: center;

  @media (max-width: base.$phone) {
    width: 100%;
  }

  // 720
  @media only screen and (min-width: base.$tablet) {
    flex-direction: row;
    width: 80%;
    margin: 5em 1em 8em 1em;
    padding: 2em;
    max-width: 40em;
    text-align: left;
  }

  @media only screen and (min-width: base.$desktop) {
    margin: auto 0;
    position: relative;
    top: -1.5em;
    max-width: 38em;
  }
}

.logo {
  flex-shrink: 0;
  max-width: 13em;

  @media screen and (min-width: base.$tablet) {
    max-width: 14em;
  }

  @media (max-width: base.$phone) {
    flex-shrink: 1;
    max-width: 9em;
  }
}

.line {
  display: none;

  // 720
  @media only screen and (min-width: base.$tablet) {
    display: inline-block;
    border-left: 2px solid #fff;
    height: 9.5em;
    padding-right: 1.5em;
    margin-left: 1.5em;
  }
}

.header-container {
  width: 100% !important;
  // 720
  @media only screen and (min-width: base.$tablet) {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: flex-start;

    > div {
      margin: 0 0 1rem 0;
    }

    > p {
      text-align: left;
    }
  }
}

.hero-header {
  width: 100% !important;
  font-size: 1.8em;
  margin: 1em 0 0.6em 0;
  letter-spacing: 0.04em;
  @media (max-width: base.$phone), (max-width: base.$tablet) {
    font-size: 1.2em;
  }
}

.hero-sub-header {
  width: 100% !important;
  font-weight: 300;
  text-align: center;
  margin: 0;
  letter-spacing: 0.02em;
  line-height: 1.25em;
  font-size: 1.1em;
  @media (max-width: base.$phone), (max-width: base.$tablet) {
    font-size: 0.8em;
  }
}
