@use '../partials/base';

.current-ballot-title {
  color: base.$primary-color;
  font-size: 1.3em;
  padding: 1.5em 1em;
  text-align: center;

  @media screen and (min-width: base.$phone) {
    font-size: 1.5em;
  }
}

.current-ballot {
  background-color: white;
  display: grid;
  display: -ms-grid;
  justify-items: center;
  padding: 1em 1.5em 2em 1.5em;
  -ms-grid-columns: 1fr 3fr;

  @media screen and (min-width: base.$xlarge) {
    grid-template-columns: 1fr 3fr;
  }
}

.progress-graphic {
  -ms-grid-column: 1;

  @media screen and (min-width: base.$phone) {
    width: 14em;
    height: 14em;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .progress-graphic {
    width: 100% !important;
  }

  .halted-graphic,
  .caution-graphic {
    height: 45%;
    margin: 2.5em 0.5em !important;
    padding: 0.7em 0 !important;
  }
}

@supports (-ms-ime-align: auto) {
  .progress-graphic {
    width: 100% !important;
  }

  .halted-graphic,
  .caution-graphic {
    height: 35%;
    margin: 3.5em 1em !important;
    padding: 1em 0 !important;
    font-size: 1em !important;
  }
}

.halted-graphic {
  background-color: red;
  margin: 1.5em 0;
  padding: 0.5em 1.5em;
  color: white;
  font-size: 1.5em;
  display: inline-block;
  align-self: center;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.3;

  @media screen and (min-width: base.$xlarge) {
    margin: 0 2em 0 1em;
  }
}

.caution-graphic {
  background-color: #ffca2b;
  margin: 1.5em 0;
  padding: 0.5em 1.5em;
  color: white;
  font-size: 1.5em;
  display: inline-block;
  align-self: center;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.3;

  @media screen and (min-width: base.$xlarge) {
    margin: 0 2em 0 1em;
  }
}

.current-ballot-status {
  color: base.$text-gray;
  -ms-grid-column: 2;
  -ms-grid-column-span: 2;
  width: 100%;
}

.election-name {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.1em;
  color: base.$primary-color;
  border-bottom: 0.5px solid #f0f0f0;
  padding-bottom: 1em;
  padding-top: 0.8em;

  @media screen and (min-width: base.$phone) {
    text-align: left;
  }

  @media screen and (min-width: base.$xlarge) {
    grid-column: 1 / span 2;
    -ms-grid-column: 1 / span 2;
  }
}

.expected-delivery-date {
  font-size: 0.9em;
}

.ballot-path {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row: 3;
  width: 100%;

  @media screen and (min-width: base.$xlarge) {
    grid-column: 1 / span 2;
    grid-row: 3;
  }
}

.ballot-step-wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 0.9em;
}

.ballot-step-wrapper .step-style {
  color: #767574;
}

.ballot-step-wrapper.future .step-style {
  color: #bdbdbe;
}

.ballot-step-wrapper.future .no-future {
  display: none;
}

.ballot-step-wrapper .only-future {
  display: none;
}

.ballot-step-wrapper.future .only-future {
  display: unset;
}

.ballot-summary {
  margin-top: 1em;
  margin-bottom: 0.5em;
  padding-bottom: 0.4em;
}

.ballot-summary-date {
  color: base.$text-gray;
  padding-top: 0.5em;
}

.ballot-detail {
  font-style: italic;
  line-height: 1.5em;
  margin-bottom: 1em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: none;
}

.step-style > .ballot-detail {
  margin-left: 2em;
}

.step-style {
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #f0f0f0;
}

.ballot-step-wrapper:last-child div {
  border-bottom: none;
}

.timeline-style {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 75px;
}

.current-ballot-details-header {
  display: block;
  background-color: #f0f0f0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-size: 1.2em;
  color: #6c6a6a;
  -ms-grid-row: 2;
  -ms-grid-column-span: 3;
  overflow: hidden;

  @media screen and (min-width: base.$xlarge) {
    grid-column: 1 / span 2;
  }
}

.details-collapsable-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2em 0;
  user-select: none;
  transition: background-color 0.5s ease-out;
  overflow: hidden;

  @media only screen and (min-width: base.$tablet) {
    padding: 0.3em 0;
  }

  img {
    // !important is a fix for ie11 centering of text, it defaults img with src svgs to 100% width it seems
    width: 30px !important;
    height: 30px;
    transition: transform 0.3s ease-out;
  }
}
