.delivery-confirmation-status-confirmed {
  display: flex;
  justify-content: right;
  color: #2b9d2b;
  font-style: italic;
  font-size: 0.75em;
  margin-top: -1.5em;
}

.delivery-confirmation-status-unconfirmed {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fdb9036c;
  padding: 10px;
  margin: 0 0 1em 0;
  font-size: 1em;
  border: 1px solid black;

  .opt-in {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }

  .confirmation-status {
    color: black;
    font-weight: bold;

    &.confirmed {
      color: #2b9d2b;
    }
  }

  .verification-link,
  .enter-confirmation-code-link {
    color: #2525fd;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 1em;
  }

  .enter-confirmation-code-button {
    width: 13em;
    margin-top: 0.25em;
  }
}

.confirmation-code-inline {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  margin-bottom: 0.25em;

  img {
    margin-right: 10px;
  }

  button {
    padding: 0.25em;
    width: unset;
    margin-left: 0.25em;
  }
}

.email-resend-countdown {
  color: black;
  font-size: 0.8em;
}
