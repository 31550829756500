@use '../partials/base';

.login-form {
  @media screen and (min-width: base.$desktop) {
    flex: 1 0 auto;
    margin: 0 0 3em 0;
  }

  .form-row {
    display: flex;
    flex-flow: column;

    label {
      display: block;
      font-size: 1em;
      color: base.$primary-color;
      margin: 2.5em 0 0.5em 0;
      @media (max-width: base.$phone) {
        margin: 0.5em 0 0.5em;
      }
    }

    label:first-of-type {
      margin-top: 3.5em;
      @media (max-width: base.$phone) {
        margin-top: 0;
      }
    }

    @media screen and (min-width: base.$tablet) {
      flex-flow: row;
      justify-content: space-between;

      label {
        font-size: 1.15em;
        margin: 2.5em 0 1em 0;
      }

      section {
        width: 47%;
      }
    }

    @media screen and (min-width: base.$desktop) {
      flex-flow: column;
      justify-content: space-between;

      label {
        font-size: 1em;
        margin: 1.75em 0 0.8rem 0 !important;
      }

      section {
        width: 100%;
      }
    }

    @media (max-width: base.$phone) {
      margin-bottom: 0.5em;
    }
  }

  section input {
    margin-bottom: 0.3em;
    width: 100%;
    border: none;
    border-bottom: 1px solid grey;
    font-size: 1.1em;

    @media screen and (min-width: base.$desktop) {
      font-size: 1em;
      margin-bottom: 0;
    }
  }

  input::placeholder {
    color: #c0c0c0;
  }

  .field-message {
    padding: 0.8em 0 0 0;
    font-style: italic;
    color: #696969;
  }

  .error {
    font-style: italic;
    color: base.$alert-color;
  }

  .form-buttons {
    display: flex;
    justify-content: center;
  }

  .login-button {
    text-align: center;
    border: none;
    border-radius: 3px;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #03314c;
    color: white;
    padding: 0.8em;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;

    @media only screen and (min-width: base.$tablet) {
      margin-top: 3.5em;
      margin-bottom: 3.5em;
      padding: 1em;
      width: 60%;
    }

    @media only screen and (min-width: base.$desktop) {
      margin-top: 2.5em;
      margin-bottom: 0;
      width: 100%;
    }
  }
}

.login-loading {
  height: 100%;
  display: flex;
  align-items: center;

  > div {
    background-color: white;
  }
}

// Styles for QR Login
.qr-login-page {
  height: 100%;
}

.qr-language-dropdown-container {
  display: flex;
  justify-content: flex-end;
  background-color: base.$primary-color;
  height: 4em;
  width: 100%;
  padding: 0.5em;

  @media only screen and (min-width: base.$largePhone) {
    margin-bottom: 0;
  }
}

.outer-qr-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0c3a58;
  height: 100%;

  .qr-blue-background {
    background-color: #f0f0f0;
    max-width: none;
    height: 100%;
    border: solid 4px #f0f0f0;
  }

  .qr-login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .qr-logo-container {
    display: flex;
    justify-content: center;
    background-color: #0c3a58;
    width: 100%;
    padding: 0.5em 1em;
    margin-bottom: 0.5em;
  }

  .account-logo {
    width: auto;
    max-width: 280px;
    max-height: 300px;
  }

  .qr-login-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 0.5em 2em;
    background-color: #f0f0f0;
  }

  .dob-text {
    color: #767676;
    font-size: 13px;
    font-style: italic;
    text-align: left;
    width: 100%;
  }

  .dob-input {
    width: 100%;
  }

  .qr-error-text {
    color: base.$error-red;
  }

  .dob-example {
    color: #767676;
    font-size: 14px;
    margin-top: 1em;
    text-align: left;
  }

  .dob-label {
    color: #0b314d;
    font-size: 16px;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .login-button {
    margin-top: 1em;
    padding: 1em;
    width: 100%;
  }
}

@media only screen and (min-width: base.$largePhone) {
  .outer-qr-login-container {
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 12% 12%;
    background-color: #f0f0f0;

    .qr-blue-background {
      background-color: #0c3a58;
      max-width: 450px;
      height: auto;
      margin-top: auto;
      margin-bottom: auto;
    }

    .qr-logo-container {
      margin-top: 0.5em;
    }

    .qr-login-container {
      margin: 0 1em 1em 1em;
      padding: 1em;
    }
  }
}
