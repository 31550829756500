@use '../partials/base';

.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em;
  margin: 1em;
  padding-top: 1.4em;

  .dashboard-ballot-status-tab {
    margin-top: 2em;
    width: unset;
  }

  @media screen and (min-width: base.$largeTablet) {
    grid-template-columns: 2fr 1fr;
    margin: 0 2em;

    .dashboard-grid-help {
      margin-top: 6.1em;
    }

    .dashboard-grid-help-tabbed {
      margin-top: 2em;
    }

    .dashboard-grid-footer {
      grid-column: span 2;
    }
  }

  @media screen and (min-width: base.$xlarge) {
    margin: 0 8%;
  }

  @media only screen and (min-width: base.$high-res) {
    margin: 0 15%;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .dashboard-grid {
    display: -ms-grid;
    -ms-grid-columns: 2fr 1fr;

    .grid-item {
      margin: 0.7em;
    }

    .dashboard-grid-footer {
      -ms-grid-column-span: 2;
      -ms-grid-row: 2;
    }

    .dashboard-grid-prefs {
      -ms-grid-column: 2;
    }
  }
}

.dashboard-title {
  color: base.$primary-color;
  font-size: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 2em;
  padding-bottom: 1em;

  @media screen and (min-width: base.$phone) {
    font-size: 1.5em;
  }
}

.dashboard-card {
  box-shadow: 1px 3px 5px 1px #ccc;
  border-radius: 6px;
  background-color: white;
  color: base.$text-gray;
  min-height: 18em;
}

.dashboard-card-header {
  background-color: base.$primary-color;
  color: white;
  height: 3.5em;
  display: flex;
  align-items: center;
  padding: 1em;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 5px solid rgb(1, 133, 187);

  @media screen and (max-width: base.$xsmall) {
    font-size: 0.8em;
    letter-spacing: 1px;
  }
}
